import { Box, useMediaQuery } from "@mui/material";
import React, { useContext } from "react";
import PoList from "./PoList";
import PrintableQRCode from "../../../component/PrintableQRCode";
import { useTheme } from "@emotion/react";
import PageContent from "../../../component/PageContent";

const PosPage = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <PageContent>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {!isSmallScreen && (
          <PrintableQRCode
            url={`${window.location.host}/pending-pos`}
            displaySize={100}
            printSize={100}
            subtext="Pending PO's"
          />
        )}
      </div>
      <h2 style={{ textAlign: "center" }}>POs</h2>
      <PoList />
    </PageContent>
  );
};

export default PosPage;
