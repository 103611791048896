import { useEffect, useState } from "react";
import InventoryTracking from "../api/InventoryTracking";
import escapeCharacters from "../helpers/escapeCharacters";

const PO_STATUS_PENDING = "PENDING";
const PO_STATUS_RECEIVED = "RECEIVED";
const PO_STATUS_POSTED = "POSTED"; // New
const PO_STATUS_ALL = "ALL";

const usePos = (authToken, limit, defaultStatus = PO_STATUS_PENDING) => {
  // Pagination
  const [results, setResults] = useState([]);
  const [locations, setLocations] = useState([]);

  const [vendors, setVendors] = useState([]);

  const [location, setLocation] = useState();
  const [vendor, setVendor] = useState(0);
  const [status, setStatus] = useState(defaultStatus);

  const [searchQuery, setSearchQuery] = useState("");
  const [refreshing, setRefreshing] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [hasMore, setHasMore] = useState(false);

  const getLocations = async () => {
    try {
      const response = await InventoryTracking.get(`/locations`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      console.log("locations", response.datalocations);
      setLocations(response.data.locations);
    } catch (error) {
      console.log(error);
    }
  };

  const getVendorsByLocation = async () => {
    try {
      const response = await InventoryTracking.get(`/vendors?location=SDL`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      console.log("vendors", response.data.vendors);
      let vendorsList = [...response.data.vendors];
      for (let vendor of vendorsList) {
        vendor.label = vendor.provider;
      }
      setVendors(vendorsList);
    } catch (error) {
      console.log(error);
    }
  };

  const search = async (controller) => {
    try {
      if (pageNumber == 0) setRefreshing(true);
      else {
        setLoadingMore(true);
      }
      let data = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      if (controller) {
        data.signal = controller.signal;
      }

      const res = await InventoryTracking.get(
        `/po?search=${escapeCharacters(
          searchQuery
        )}&pageNumber=${pageNumber}&limit=${limit}&status=${status}&vendor=${vendor}`,
        data
      );

      // If first page, set results as current data
      // If beyond first page, add result to current data
      if (pageNumber === 0) {
        setResults(res.data.page.results);
      } else {
        setResults((prev) => [...prev, ...res.data.page.results]);
      }
      setTotal(res.data.page.total);
      setPages(res.data.page.pages);
    } catch (error) {
      console.log(error);
    } finally {
      setRefreshing(false);
      setLoadingMore(false);
    }
  };

  // Find the next Page Number based on the total length of the current data and the page size
  // If results.length = 10
  // And limit = 10
  // then results.length/limit = 1
  const onLoadMore = () => {
    let nextPage;
    if (results.length > 0) {
      nextPage = Math.ceil(results.length / limit);
    } else {
      nextPage = 0;
    }
    setPageNumber(nextPage);
  };

  // On swipe down (to refresh page) we want to reload all the data from the first page
  // without removing the search text
  const onRefresh = () => {
    if (pageNumber == 0) {
      search();
    } else {
      setPageNumber(0);
    }
  };

  // When Search Text is Updated After Debounce (1 sec), reset the page number to 0
  useEffect(() => {
    setPageNumber(0);
  }, [searchQuery, status, vendor]);

  useEffect(() => {
    const controller = new AbortController();

    if (pageNumber != undefined && limit != undefined) search(controller);

    return () => {
      controller.abort(); // abort api call on unmount for cleanup
    };
  }, [searchQuery, status, vendor, pageNumber, limit]);

  useEffect(() => {
    setHasMore(results.length != total);
  }, [results, total]);

  useEffect(() => {
    if (locations.length === 0) getLocations();
  }, []);

  useEffect(() => {
    getVendorsByLocation();
  }, []);

  useEffect(() => {
    getVendorsByLocation();
    setVendor(0);
  }, [location]);

  useEffect(() => {
    console.log(results);
  }, [results]);

  return {
    searchQuery,
    setSearchQuery,
    status,
    setStatus,
    results,
    onRefresh,
    refreshing,
    onLoadMore,
    loadingMore,
    total,
    hasMore,
    vendors,
    setVendor,
    vendor,
  };
};

export default usePos;
