import { useContext, useEffect, useState } from "react";
import InventoryTracking from "../../../../../api/InventoryTracking";
import ListItem from "../../../../../component/ListItem";
import { useNavigate } from "react-router";
import { useTheme } from "@emotion/react";
import { AuthContext } from "../../../../../context/AuthContext";
import { SnackAlertContext } from "../../../../../context/SnackAlertContext";
import PendingPoModal from "../../../../../component/PendingPoModal";
import momentPhoenix from "../../../../../helpers/momentPhoenix";

const PurchaseOrdersTab = ({ jobId }) => {
  const [pendingPos, setPendingPos] = useState([]);
  const [pendingPoModalData, setPendingPoModalData] = useState(null);
  const { authToken } = useContext(AuthContext);

  const { openSnackMessage } = useContext(SnackAlertContext);
  let navigate = useNavigate();
  const theme = useTheme();

  const getPendingJobPos = async () => {
    try {
      const response = await InventoryTracking.get(
        `/jobs/${jobId}/pending-pos`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      console.log("Pending Job POs", response.data.pos);
      setPendingPos(response.data.pos);
    } catch (error) {
      console.log(error);
    }
  };

  const getPendingPoById = async (id) => {
    try {
      const response = await InventoryTracking.get(`/po/${id}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      console.log("pending po", response.data);
      setPendingPoModalData({
        ...response.data,
        imageBlobs: [],
        imageUrls: [],
      });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getPendingJobPos();
  }, []);

  return (
    <div>
      {pendingPos &&
        pendingPos.map((po, index) => {
          let itemDetails = [];
          let emphasize = [];
          let highlightOn = true;
          let highlightColor = "#AAC6E0";
          if (po.job_name) {
            itemDetails.push(`Job: ${po.job_name}`);
            emphasize.push(`Job: ${po.job_name}`);
          }

          if (po.poid.includes("INV-")) {
            if (po.status === 8) {
              if (po.is_partially_received == 1) {
                highlightOn = true;
                highlightColor = "#FEFBE3";
                itemDetails.push(`Status: PARTIALLY RECEIVED`);
                emphasize.push(`Status: PARTIALLY RECEIVED`);
              } else if (po.is_partially_received == 0) {
                highlightOn = true;
                highlightColor = "#DAE7D4";
                itemDetails.push(`Status: READY TO POST`);
                emphasize.push(`Status: READY TO POST`);
              } else {
                // po.is_partially_received == null
                itemDetails.push(`Status: NOT YET RECEIVED`);
                emphasize.push(`Status: NOT YET RECEIVED`);
              }
            } else {
              itemDetails.push(`Status: RECEIVED AND POSTED`);
              emphasize.push(`Status: RECEIVED AND POSTED`);
              highlightColor = "#d2d2d2";
            }
          }
          // Non-Inventory/Regular PO
          else if (po.is_received == 1) {
            // PO is Received and Posted
            if (po.status == 9) {
              itemDetails.push(`Status: RECEIVED AND POSTED`);
              emphasize.push(`Status: RECEIVED AND POSTED`);
              highlightColor = "#d2d2d2";
            } // PO is Received and Ready to Post
            else {
              itemDetails.push(`Status: RECEIVED AND READY TO POST`);
              emphasize.push(`Status: RECEIVED AND READY TO POST`);
              highlightColor = "#DAE7D4";
            }
          } else {
            if (po.is_partially_received == null) {
              // po.is_partially_received == null
              itemDetails.push(`Status: NOT YET RECEIVED`);
              emphasize.push(`Status: NOT YET RECEIVED`);
            } else {
              // If po.is_partially_received == 1 or 0 just mark it as partially received
              // It is not Ready to Post until the whole PO is marked as received
              highlightOn = true;
              highlightColor = "#FEFBE3";
              itemDetails.push(`Status: PARTIALLY RECEIVED`);
              emphasize.push(`Status: PARTIALLY RECEIVED`);
            }
          }

          if (po.vendor_name) itemDetails.push(`Vendor: ${po.vendor_name}`);
          if (po.date_created)
            itemDetails.push(
              `Order Date: ${momentPhoenix(po.date_created).format(
                "MM/DD/YY hh:mm A z"
              )}`
            );
          if (po.jobsite_delivery_type_description)
            itemDetails.push(
              `Jobsite Delivery Type: ${po.jobsite_delivery_type_description}`
            );
          return (
            <ListItem
              key={index}
              title={`${po.poid} - ${
                po.deliver_type_description
                  ? po.deliver_type_description
                  : `Deliver to ${
                      po.delivery_location[0].toUpperCase() +
                      po.delivery_location.slice(1).toLowerCase()
                    }`
              }`}
              details={itemDetails}
              emphasize={emphasize}
              onClick={() => {
                getPendingPoById(po.id);
              }}
              highlightColor={highlightColor}
              highlightOn={highlightOn}
            />
          );
        })}
      <PendingPoModal
        pendingPoModalData={pendingPoModalData}
        setPendingPoModalData={setPendingPoModalData}
        // receivePendingPo={receivePendingPo}
        // isLoading={isLoading}
        onUpdatePo={() => {
          // Reset page data
          getPendingJobPos();
        }}
      />
    </div>
  );
};

export default PurchaseOrdersTab;
