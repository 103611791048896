import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardActionArea,
  Checkbox,
  IconButton,
  Modal,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import toFixedIfNecessary from "../../../../../helpers/toFixedIfNecessary";
import SelectVehicle from "./SelectVehicle";
import { useTheme } from "@emotion/react";
import momentPhoenix from "../../../../../helpers/momentPhoenix";

const DeliverFromStaging = ({
  isLoading,
  stagedTransactions,
  setStagedTransactions,
  deliverItems,
  loadItemsOnTruck,
  previewModalOpen,
  setPreviewModalOpen,
  selectVehicleModalOpen,
  setSelectVehicleModalOpen,
  onTransactionClick,
}) => {
  const theme = useTheme();
  const [] = useState(false);

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleTransactionCheckboxEvent = (e, transactionIndex) => {
    let isChecked = e.target.checked;

    let stagedTransactionsCopy = [...stagedTransactions];
    let transactionItems = stagedTransactionsCopy[transactionIndex].items;
    let itemIndex;

    // If Checking, set all items to checked
    // If Unchecking, set all items to unchecked
    if (isChecked) {
      console.log("checked");
      stagedTransactionsCopy[transactionIndex].checked = true;
      for (itemIndex = 0; itemIndex < transactionItems.length; itemIndex++) {
        transactionItems[itemIndex].quantity =
          transactionItems[itemIndex].txn_leftover;
      }
    } else {
      console.log("unchecked");
      stagedTransactionsCopy[transactionIndex].checked = false;
      for (itemIndex = 0; itemIndex < transactionItems.length; itemIndex++) {
        transactionItems[itemIndex].quantity = 0;
      }
    }
    console.log(stagedTransactionsCopy);
    setStagedTransactions(stagedTransactionsCopy);
  };

  const handleItemQuantityChangeEvent = (e, transactionIndex, itemIndex) => {
    let val;
    if (
      e.target.value == "" ||
      e.target.value === undefined ||
      e.target.value === null ||
      e.target.value === "0" ||
      e.target.value === "00"
    ) {
      val = 0;
    } else {
      val = e.target.value.replace(/^0+/, "");
      val = toFixedIfNecessary(val, 2);
    }
    // Do Not Allow Negative
    if (val < 0) {
      val = 0;
    }

    let stagedTransactionsCopy = [...stagedTransactions];

    // Check or Uncheck current item
    let currentTransaction = stagedTransactionsCopy[transactionIndex];
    let currentItem = currentTransaction.items[itemIndex];

    // Do Not Allow to go beyond txn leftover
    if (val > currentItem.txn_leftover) {
      val = currentItem.txn_leftover;
    }

    currentItem.quantity = val;

    // If item quantity is set to equal the total available count, check sibling items
    // If not all siblings are equal to capacity, uncheck the transaction
    // if all are equal to capacity, check the transaction
    if (currentItem.quantity < currentItem.txn_leftover) {
      currentTransaction.checked = false;
    } else {
      currentTransaction.checked = true;
      for (const item of currentTransaction.items) {
        if (item.quantity < item.txn_leftover)
          currentTransaction.checked = false;
      }
    }
    setStagedTransactions(stagedTransactionsCopy);
  };

  return (
    <div>
      {
        // Load On Truck Or Deliver
        stagedTransactions && (
          <Modal
            open={previewModalOpen}
            onClose={() => {
              setPreviewModalOpen(false);
            }}
          >
            <Card
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                minWidth: 300,
                maxWidth: 500,
                maxHeight: "100%",
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 4,
                display: "block",
                overflow: "scroll",
                overflowX: "hidden",
              }}
            >
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Confirm Items for Delivery
              </Typography>
              <div>
                <div
                  style={{
                    borderRadius: 0,
                    marginTop: 1,
                    display: "flex",
                    flexDirection: "row",
                    marginTop: 8,
                    //cursor: "pointer",
                  }}
                >
                  <div style={{ flex: 1, fontSize: 12 }}>
                    <p
                      style={{
                        margin: 0,
                        fontWeight: "bold",
                        //textAlign: "center",
                      }}
                    >
                      Description
                    </p>
                  </div>
                  <div style={{ minWidth: 80, fontSize: 12 }}>
                    <p
                      style={{
                        margin: 0,
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      UOM
                    </p>
                  </div>
                  <div style={{ minWidth: 60, fontSize: 12 }}>
                    <p
                      style={{
                        margin: 0,
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      Delivered
                    </p>
                  </div>
                  <div style={{ minWidth: 100, fontSize: 12 }}>
                    <p
                      style={{
                        margin: 0,
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      Quantity
                    </p>
                  </div>
                </div>
                {stagedTransactions.map((stagedTransaction, index) => (
                  <div key={index}>
                    {stagedTransaction.items.map((item, itemIndex) => {
                      if (item.quantity > 0) {
                        return (
                          <div
                            key={itemIndex}
                            style={{
                              borderRadius: 0,
                              marginTop: 1,
                              display: "flex",
                              flexDirection: "row",
                              marginTop: 8,
                              position: "relative",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                flex: 1,
                                fontSize: 12,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <p style={{ flex: 1, margin: 0 }}>
                                {item.description}
                              </p>
                            </div>
                            <div
                              style={{
                                width: 80,
                                fontSize: 12,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <p style={{ margin: 0, textAlign: "center" }}>
                                {item.uom}
                              </p>
                            </div>
                            <div
                              style={{
                                width: 60,
                                fontSize: 12,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <p style={{ margin: 0, textAlign: "center" }}>
                                {item.original_transaction_quantity -
                                  item.txn_leftover}
                                /{item.original_transaction_quantity}
                              </p>
                            </div>
                            <TextField
                              disabled
                              style={{
                                width: 80,
                              }}
                              label="Quantity"
                              type="number"
                              value={`${item.quantity}`}
                            />
                          </div>
                        );
                      }
                    })}
                  </div>
                ))}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: 16,
                }}
              >
                <Button
                  disabled={isLoading}
                  variant="contained"
                  onClick={async () => {
                    setSelectVehicleModalOpen(true);
                  }}
                >
                  Loaded On Truck
                </Button>
                <Button
                  disabled={isLoading}
                  variant="contained"
                  onClick={() => {
                    deliverItems();
                  }}
                >
                  Delivered
                </Button>
              </div>
            </Card>
          </Modal>
        )
      }
      {
        // Select Truck if Load On Truck Selected
        stagedTransactions && (
          <Modal
            open={selectVehicleModalOpen}
            onClose={() => {
              setSelectVehicleModalOpen(false);
            }}
          >
            <Card
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                minWidth: 300,
                maxWidth: 500,
                maxHeight: "100%",
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 4,
                display: "block",
                overflow: "scroll",
                overflowX: "hidden",
              }}
            >
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Select Truck Loaded
              </Typography>
              <SelectVehicle
                onSelect={(vehicleId) => {
                  loadItemsOnTruck(vehicleId);
                }}
              />
              <CardActionArea
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: 16,
                }}
              >
                {/* <Button
                  disabled={isLoading}
                  variant="contained"
                  onClick={async () => {
                    await loadItemsOnTruck();
                  }}
                >
                  Loaded On Truck
                </Button> */}
                <Button
                  disabled={isLoading}
                  variant="contained"
                  onClick={() => {
                    setSelectVehicleModalOpen(false);
                  }}
                >
                  Cancel
                </Button>
              </CardActionArea>
            </Card>
          </Modal>
        )
      }
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            style={{
              height: 50,
              marginTop: 16,
            }}
            startIcon={<LocalShippingIcon />}
            variant="outlined"
            onClick={() => {
              setPreviewModalOpen(true);
            }}
          >
            Actions
          </Button>
        </div>
        <div>
          {stagedTransactions &&
            stagedTransactions.map((stagedTransaction, transactionIndex) => (
              <div key={transactionIndex}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <h4
                    style={{
                      flex: 1,
                      cursor: onTransactionClick ? "pointer" : null,
                      textDecoration: onTransactionClick ? "underline" : null,
                    }}
                    onClick={() => {
                      onTransactionClick(stagedTransaction.transaction);
                    }}
                  >
                    {momentPhoenix(stagedTransaction.stagedTransaction).format(
                      "MM/DD/YY"
                    )}{" "}
                    - {stagedTransaction.full_name} [
                    {stagedTransaction.transaction} - {stagedTransaction.vendor}
                    ]
                  </h4>
                </div>
                <div style={{}}>
                  <div
                    style={{
                      borderRadius: 0,
                      marginTop: 1,
                      display: "flex",
                      flexDirection: "row",
                      marginTop: 8,

                      alignItems: "center",
                    }}
                  >
                    <div style={{ flex: 1, fontSize: 12 }}>
                      <p
                        style={{
                          margin: 0,
                          fontWeight: "bold",
                          //textAlign: "center",
                        }}
                      >
                        Description
                      </p>
                    </div>

                    <div style={{ minWidth: 80, fontSize: 12 }}>
                      <p
                        style={{
                          margin: 0,
                          fontWeight: "bold",
                          textAlign: "center",
                        }}
                      >
                        UOM
                      </p>
                    </div>
                    <div style={{ minWidth: 60, fontSize: 12 }}>
                      <p
                        style={{
                          margin: 0,
                          fontWeight: "bold",
                          textAlign: "center",
                        }}
                      >
                        Delivered
                      </p>
                    </div>
                    <div
                      style={{
                        minWidth: 80,
                        fontSize: 12,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <p
                        style={{
                          margin: 0,
                          fontWeight: "bold",
                          textAlign: "center",
                        }}
                      >
                        Qty
                      </p>
                      <Checkbox
                        checked={stagedTransactions[transactionIndex].checked}
                        onChange={(e) => {
                          handleTransactionCheckboxEvent(e, transactionIndex);
                        }}
                      />
                    </div>
                  </div>
                  {stagedTransaction &&
                    stagedTransaction.items &&
                    stagedTransaction.items.map((item, itemIndex) => {
                      return (
                        <div
                          key={itemIndex}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <div
                            key={itemIndex}
                            style={{
                              flex: 1,
                              borderRadius: 0,
                              marginTop: 1,
                              display: "flex",
                              flexDirection: "row",
                              marginTop: 8,
                              position: "relative",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                flex: 1,
                                fontSize: 12,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <p style={{ flex: 1, margin: 0 }}>
                                {item.description}
                              </p>
                            </div>

                            <div
                              style={{
                                width: 80,
                                fontSize: 12,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <p
                                style={{
                                  margin: 0,
                                  textAlign: "center",
                                }}
                              >
                                {item.uom}
                              </p>
                            </div>
                            <div
                              style={{
                                width: 60,
                                fontSize: 12,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <p style={{ margin: 0, textAlign: "center" }}>
                                {item.original_transaction_quantity -
                                  item.txn_leftover}
                                /{item.original_transaction_quantity}
                              </p>
                            </div>
                            <TextField
                              style={{
                                width: 80,
                              }}
                              label={`Max ${item.txn_leftover}`}
                              type="number"
                              value={`${item.quantity}`}
                              onChange={(e) => {
                                handleItemQuantityChangeEvent(
                                  e,
                                  transactionIndex,
                                  itemIndex
                                );
                              }}
                            />
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default DeliverFromStaging;
