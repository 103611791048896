import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback,
} from "react";
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import InventoryTracking from "../../../api/InventoryTracking";
import { AuthContext } from "../../../context/AuthContext";
import PageContent from "../../../component/PageContent";

const Staged = () => {
  const navigate = useNavigate();
  const { authToken, userBranch } = useContext(AuthContext);

  const [stagedTransactions, setStagedTransactions] = useState([]);
  const [locations, setLocations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const theme = useTheme();

  const getCurrentStaged = async () => {
    try {
      setIsLoading(true);
      const response = await InventoryTracking.get(`/staged-transactions`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      console.log(response.data);
      setStagedTransactions(response.data.staged);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const controller = new AbortController();

    getCurrentStaged();
    return () => {
      controller.abort(); // abort api call on unmount for cleanup
    };
  }, []);

  return (
    <PageContent>
      <TableContainer component={Box}>
        <Table sx={{}} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Job Name</TableCell>
              <TableCell>ID</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {stagedTransactions &&
              stagedTransactions.map((transaction, index) => (
                <TableRow
                  onClick={() => {
                    navigate(`/jobs/${transaction.project_backlog_id}/staged`);
                  }}
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>{transaction.job}</TableCell>
                  <TableCell>
                    {transaction.full_name} [{transaction.transaction}]
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </PageContent>
  );
};

export default Staged;
