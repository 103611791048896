import React, { useContext, useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Card,
  Divider,
  ListItemText,
  MenuItem,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import InventoryTracking from "../../../api/InventoryTracking";
import { SnackAlertContext } from "../../../context/SnackAlertContext";
import PendingPoModal from "../../../component/PendingPoModal";
import PrintableQRCode from "../../../component/PrintableQRCode";
import PageContent from "../../../component/PageContent";
import { useTheme } from "@emotion/react";
import { DarkLightModeContext } from "../../../context/DarkLightModeContext";
import momentPhoenix from "../../../helpers/momentPhoenix";
import InfiniteScroller from "../../../component/InfiniteScroller/InfiniteScroller";
import ListFooter from "../../../component/InfiniteScroller/ListFooter";
import usePos from "../../../customHooks/usePos";

const PO_STATUS_PENDING = "PENDING";
const PO_STATUS_RECEIVED = "RECEIVED";
const PO_STATUS_POSTED = "POSTED"; // New
const PO_STATUS_ALL = "ALL";

const PoList = ({
  hideStatusFilter = false,
  defaultStatus = PO_STATUS_PENDING,
}) => {
  const navigate = useNavigate();
  const { poId } = useParams();
  const { authToken, userBranch } = useContext(AuthContext);
  const { openSnackMessage } = useContext(SnackAlertContext);
  const [vendorSearch, setVendorSearch] = useState("");
  const [pendingPos, setPendingPos] = useState([]);
  const [pendingPoModalData, setPendingPoModalData] = useState(null);
  const theme = useTheme();
  const { isDarkMode } = useContext(DarkLightModeContext);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [isLoading, setIsLoading] = useState(false);
  const [poStatus, setPoStatus] = useState(PO_STATUS_PENDING);

  // const searchAndPagination = useSearchAndPagination(userBranch);
  // const { search, location, page, limit, setPages, setPage } =
  //   searchAndPagination;

  const {
    searchQuery,
    setSearchQuery,
    status,
    setStatus,
    results,
    onRefresh,
    refreshing,
    onLoadMore,
    loadingMore,
    total,
    hasMore,
    vendors,
    vendor,
    setVendor,
  } = usePos(authToken, 25, defaultStatus);

  const getPendingPoById = async (id) => {
    try {
      const response = await InventoryTracking.get(`/po/${id}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      console.log("pending po", response.data);
      setPendingPoModalData({
        ...response.data,
        imageBlobs: [],
        imageUrls: [],
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: isSmallScreen ? "column" : "row",
          alignItems: isSmallScreen ? "flex-end" : "center",
          justifyContent: isSmallScreen ? "inherit" : "flex-end",
        }}
      >
        <TextField
          id="search"
          label="Search..."
          variant="outlined"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          size="small"
          sx={{
            width: isSmallScreen ? "100%" : "270px",
          }}
        />
        <Autocomplete
          // fullWidth={true}
          size="small"
          sx={{
            marginTop: isSmallScreen ? "12px" : 0,
            marginLeft: isSmallScreen ? 0 : "12px",
            width: isSmallScreen ? "100%" : "270px",
          }}
          disablePortal
          inputValue={vendorSearch}
          onInputChange={(event, newInputValue) => {
            setVendorSearch(newInputValue);
          }}
          options={vendors}
          renderInput={(params) => (
            <TextField size="small" label="Vendor" {...params} />
          )}
          onChange={(event, value) => {
            console.log("ONCHANGE", value);
            if (value) {
              setVendor(value.id);
            } else {
              setVendor(0);
            }
          }}
        />

        {!hideStatusFilter && (
          <TextField
            select
            value={status}
            label="Status"
            onChange={(e) => setStatus(e.target.value)}
            size="small"
            sx={{
              marginTop: isSmallScreen ? "12px" : 0,
              marginLeft: isSmallScreen ? 0 : "12px",
              width: isSmallScreen ? "100%" : "270px",
            }}
          >
            <MenuItem value={PO_STATUS_PENDING}>PENDING</MenuItem>
            <MenuItem value={PO_STATUS_RECEIVED}>RECEIVED</MenuItem>
            <MenuItem value={PO_STATUS_POSTED}>POSTED</MenuItem>
            <MenuItem value={PO_STATUS_ALL}>ALL</MenuItem>
          </TextField>
        )}
      </div>
      <div>
        <InfiniteScroller
          items={results}
          renderItem={(po, index) => {
            let itemDetails = [];
            // let emphasize = [];
            // let highlight = [];
            // let highlightOn = true;
            // const blue = "#7ab4eb";
            // const yellow = "#e6e681";
            // const green = "#03ab39";
            // const grey = "#d2d2d2";

            // let highlightColor = blue;
            let jobName;
            let status;

            if (po.job_name) {
              jobName = po.job_name;
              itemDetails.push(`Job: ${po.job_name}`);
              // emphasize.push(`Job: ${po.job_name}`);
            }

            if (po.poid.includes("INV-")) {
              if (po.status === 8) {
                if (po.is_partially_received == 1) {
                  // highlightOn = true;
                  // highlightColor = blue;
                  status = `PARTIALLY RECEIVED`;
                  itemDetails.push(`Status: PARTIALLY RECEIVED`);
                  // emphasize.push(`Status: PARTIALLY RECEIVED`);
                  // highlight.push(`Status: PARTIALLY RECEIVED`);
                } else if (po.is_partially_received == 0) {
                  // highlightOn = true;
                  // highlightColor = green;
                  status = `READY TO POST`;
                  itemDetails.push(`Status: READY TO POST`);
                  // emphasize.push(`Status: READY TO POST`);
                  // highlight.push(`Status: READY TO POST`);
                } else {
                  // po.is_partially_received == null
                  status = `NOT YET RECEIVED`;
                  itemDetails.push(`Status: NOT YET RECEIVED`);
                  // emphasize.push(`Status: NOT YET RECEIVED`);
                  // highlight.push(`Status: NOT YET RECEIVED`);
                }
              } else {
                status = `RECEIVED AND POSTED`;
                itemDetails.push(`Status: RECEIVED AND POSTED`);
                // emphasize.push(`Status: RECEIVED AND POSTED`);
                // highlight.push(`Status: RECEIVED AND POSTED`);
                // highlightColor = grey;
              }
            }
            // Non-Inventory/Regular PO
            else if (po.is_received == 1) {
              // PO is Received and Posted
              if (po.status == 9) {
                status = `RECEIVED AND POSTED`;
                itemDetails.push(`Status: RECEIVED AND POSTED`);
                // emphasize.push(`Status: RECEIVED AND POSTED`);
                // highlight.push(`Status: RECEIVED AND POSTED`);
                // highlightColor = grey;
              } // PO is Received and Ready to Post
              else {
                status = `RECEIVED AND READY TO POST IN BRM`;
                itemDetails.push(`Status: RECEIVED AND READY TO POST IN BRM`);
                // emphasize.push(`Status: RECEIVED AND READY TO POST IN BRM`);
                // highlight.push(`Status: RECEIVED AND READY TO POST IN BRM`);
                // highlightColor = green;
              }
            } else {
              if (po.is_partially_received == null) {
                status = `NOT YET RECEIVED`;
                itemDetails.push(`Status: NOT YET RECEIVED`);
                // emphasize.push(`Status: NOT YET RECEIVED`);
                // highlight.push(`Status: NOT YET RECEIVED`);
              } else {
                // If po.is_partially_received == 1 or 0 just mark it as partially received
                // It is not Ready to Post until the whole PO is marked as received
                // highlightOn = true;
                // highlightColor = yellow;
                status = `PARTIALLY RECEIVED`;
                itemDetails.push(`Status: PARTIALLY RECEIVED`);
                // emphasize.push(`Status: PARTIALLY RECEIVED`);
                // highlight.push(`Status: PARTIALLY RECEIVED`);
              }
            }

            let vendorName;
            if (po.vendor_name) {
              vendorName = po.vendor_name;
              itemDetails.push(`Vendor: ${po.vendor_name}`);
            }

            let orderDate;
            if (po.date_created) {
              orderDate = momentPhoenix(po.date_created).format("MM/DD/YY");
              itemDetails.push(
                `Order Date: ${momentPhoenix(po.date_created).format(
                  "MM/DD/YY"
                )}`
              );
            }

            let jobsiteDeliveryType;
            if (po.jobsite_delivery_type_description) {
              jobsiteDeliveryType = po.jobsite_delivery_type_description;
              itemDetails.push(
                `Jobsite Delivery Type: ${po.jobsite_delivery_type_description}`
              );
            }

            return (
              <>
                <Box
                  sx={{
                    padding: "16px",
                    cursor: "pointer",
                    "&:hover": {
                      background: isDarkMode
                        ? "#5A5A5A"
                        : theme.palette.secondary.light,
                    },
                  }}
                  key={index}
                  onClick={() => {
                    getPendingPoById(po.id);
                  }}
                >
                  <ListItemText
                    primary={`${po.poid} - ${
                      po.deliver_type_description
                        ? po.deliver_type_description
                        : `Deliver to ${
                            po.delivery_location[0].toUpperCase() +
                            po.delivery_location.slice(1).toLowerCase()
                          }`
                    }`}
                    secondary={
                      <>
                        {jobName && (
                          <Typography sx={{ fontSize: 12, fontWeight: "bold" }}>
                            Job: {jobName}
                          </Typography>
                        )}

                        {vendorName && (
                          <Typography sx={{ fontSize: 12 }}>
                            Vendor: {vendorName}
                          </Typography>
                        )}
                        {orderDate && (
                          <Typography sx={{ fontSize: 12 }}>
                            Order Date: {orderDate}
                          </Typography>
                        )}
                        {jobsiteDeliveryType && (
                          <Typography sx={{ fontSize: 12 }}>
                            Jobsite Delivery: {jobsiteDeliveryType}
                          </Typography>
                        )}
                      </>
                    }
                  />

                  <Box sx={{ paddingTop: "4px" }}>
                    {status && (
                      <Typography
                        // variant="subtitle2"
                        sx={{
                          fontSize: "10px",
                          fontWeight: "bold",
                          backgroundColor: "#c4c4c4",
                          display: "inline",
                          paddingTop: "8px",
                          paddingBottom: "8px",
                          paddingLeft: "16px",
                          paddingRight: "16px",
                          borderRadius: "16px",
                        }}
                      >
                        {status}
                      </Typography>
                    )}
                  </Box>
                </Box>
                <Divider />
              </>
            );
          }}
          onRefresh={onRefresh}
          onEndReached={onLoadMore}
          loading={refreshing || loadingMore}
          ListFooterComponent={() => {
            return <ListFooter hasMore={hasMore} onClick={onLoadMore} />;
          }}
        />
        <PendingPoModal
          pendingPoModalData={pendingPoModalData}
          setPendingPoModalData={setPendingPoModalData}
          //receivePendingPo={receivePendingPo}
          //saveItemQuantityReceived={saveItemQuantityReceived}
          //isLoading={isLoading}
          onUpdatePo={() => {
            // Reset page data
            onRefresh();
          }}
        />
      </div>
    </Box>
  );
};

export default PoList;
