import React, { useContext, useEffect, useState } from "react";

import { Box, MenuItem, TextField, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ListItem from "../../../component/ListItem";
import { AuthContext } from "../../../context/AuthContext";
import InventoryTracking from "../../../api/InventoryTracking";
import PrintableQRCode from "../../../component/PrintableQRCode";
import { SnackAlertContext } from "../../../context/SnackAlertContext";
import PageContent from "../../../component/PageContent";
import useVehicles from "../../../customHooks/useVehicles";
import { LocalShipping } from "@mui/icons-material";
import { useTheme } from "@emotion/react";

const Vehicles = () => {
  const navigate = useNavigate();
  const { authToken, fullName, userBranch } = useContext(AuthContext);
  const [branch, setBranch] = useState("SDL");
  // const [searchQuery, setSearchQuery, results, loading] = useVehiclesLoaded(
  //   authToken,
  //   branch
  // );
  const [searchQuery, setSearchQuery, results, loading] = useVehicles(
    authToken,
    branch
  );
  const [locations, setLocations] = useState([]);
  const { openSnackMessage } = useContext(SnackAlertContext);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const getLocations = async () => {
    try {
      const response = await InventoryTracking.get(`/locations`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      console.log(response.data);
      console.log("LOCATIONS", response.data.locations);
      setLocations(response.data.locations);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (locations.length === 0) getLocations();
  }, []);

  return (
    <PageContent>
      <div
        style={{
          position: "relative",
          flexDirection: "row",
          display: isSmallScreen ? "column" : "row",
          marginTop: 16,
          marginLeft: 16,
          marginRight: 16,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {!isSmallScreen && (
            <PrintableQRCode
              url={`${window.location.host}/vehicles`}
              displaySize={100}
              printSize={100}
              subtext="Vehicles"
            />
          )}
        </div>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: isSmallScreen ? "column" : "row",
          alignItems: isSmallScreen ? "flex-end" : "center",
        }}
      >
        <div style={{ width: isSmallScreen ? "inherit" : "100%" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: isSmallScreen ? "column" : "row",
              marginTop: "7px",
            }}
          >
            <TextField
              id="search"
              label="Search..."
              variant="outlined"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              size="small"
              sx={{
                marginRight: "10px",
              }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                marginTop: isSmallScreen ? "7px" : "0px",
                marginBottom: isSmallScreen ? "7px" : "0px",
                justifyContent: isSmallScreen ? "flex-end" : "center",
              }}
            >
              <TextField
                select
                id="branchSelect"
                value={branch}
                label="Branch"
                onChange={(e) => setBranch(e.target.value)}
                size="small"
                sx={{
                  minWidth: "100px",
                  marginRight: "10px",
                }}
              >
                {locations?.map((branch, i) => (
                  <MenuItem key={i} value={branch.location}>
                    {branch.location}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </Box>
        </div>
      </div>

      {results.map((vehicle, index) => {
        let details = [];
        let emphasize = [];
        // details.push(`Description: ${vehicle.description}`);
        // emphasize.push(`Description: ${vehicle.description}`);
        // details.push(`Number: ${vehicle.number}`);
        // emphasize.push(`Number: ${vehicle.number}`);
        // details.push(`Make: ${vehicle.make}`);
        // details.push(`Model: ${vehicle.model}`);
        // details.push(`Year: ${vehicle.year}`);
        // details.push(`VIN: ${vehicle.vin}`);
        details.push(`Plate Number: ${vehicle.plate_number}`);

        return (
          <ListItem
            key={index}
            // title={vehicle.nick_name}
            title={`Truck ${vehicle.id}`}
            details={details}
            emphasize={emphasize}
            // image={returned.img_url ? returned.img_url : undefined}
            onClick={() => {
              navigate(`/vehicles/${vehicle.id}`);
            }}
            iconRight={() => {
              if (vehicle.is_loaded) return <LocalShipping color="primary" />;
            }}
          />
        );
      })}
    </PageContent>
  );
};

export default Vehicles;
