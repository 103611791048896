import React, { useContext, useEffect, useState } from "react";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import InventoryTracking from "../../../api/InventoryTracking";
import { AuthContext } from "../../../context/AuthContext";
import PrintableQRCode from "../../../component/PrintableQRCode";
import { SnackAlertContext } from "../../../context/SnackAlertContext";
import PageContent from "../../../component/PageContent";
import DeliverFromStaging from "../Jobs/JobDetails/Tabs/DeliverFromStaging";

const StagedTransaction = () => {
  const { jobId, transactionId } = useParams();
  const [buildingId, setBuildingId] = useState(null);
  const [vendorNotes, setVendorNotes] = useState(null);
  const { authToken } = useContext(AuthContext);
  const { openSnackMessage } = useContext(SnackAlertContext);
  const [details, setDetails] = useState(null);
  const [stagedTransactions, setStagedTransactions] = useState([]);
  const [previewModalOpen, setPreviewModalOpen] = useState(false);
  let navigate = useNavigate();
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const getJobDetails = async () => {
    try {
      const response = await InventoryTracking.get(`/jobs/${jobId}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      console.log("Job Details", response.data);
      setDetails(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getCurrentStaged = async () => {
    try {
      const response = await InventoryTracking.get(`/staged/${transactionId}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      console.log(response.data);
      setStagedTransactions(response.data.staged);

      setBuildingId(response.data.staged[0].buildingId);
      setVendorNotes(response.data.staged[0].vendorNotes);
    } catch (error) {
      console.log(error);
    }
  };

  const deliverItems = async () => {
    try {
      setIsLoading(true);
      let items = [];

      // Add Selected Items in the request body
      let transactionIndex;
      let itemIndex;
      for (
        transactionIndex = 0;
        transactionIndex < stagedTransactions.length;
        transactionIndex++
      ) {
        let transaction = stagedTransactions[transactionIndex];

        transaction.checked = false;
        for (itemIndex = 0; itemIndex < transaction.items.length; itemIndex++) {
          let item = transaction.items[itemIndex];

          if (item.quantity > 0) {
            items.push(item);
          }
        }
      }

      let data = { stagedItems: items };

      const response = await InventoryTracking.post(
        `/jobs/${jobId}/delivered`,
        data,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      console.log(response.data);
      console.log("DELIVERED!", data);
      openSnackMessage("success", "Items successfull added to delivered");

      //getPageData();
      //setPreviewModalOpen(false);
      //navigate(`/jobs/${id}/delivered`);
      window.location = `/jobs/${jobId}/delivered`;
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.message);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", "Internal Server Error");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getPageData = () => {
    getJobDetails();
    getCurrentStaged();
  };

  useEffect(() => {
    getPageData();
  }, []);

  return (
    <PageContent>
      {details ? (
        <Grid container>
          <Grid
            item
            xs={12}
            sm={4}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div style={{ marginTop: 16 }}>
              {!isSmallScreen && (
                <div
                  style={{
                    background: "white",
                    padding: "16px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <PrintableQRCode
                    displaySize={150}
                    printSize={100}
                    url={`${window.location.host}/jobs/${jobId}/staged/${transactionId}`}
                    topText={`STAGED: ${details.job_name}`}
                    subtext={`${transactionId}${
                      buildingId
                        ? `
                      Bldg: ${buildingId}`
                        : ""
                    }`}
                    additionalText={`${
                      vendorNotes
                        ? `
                      Notes: ${vendorNotes}`
                        : ""
                    }`}
                  />
                </div>
              )}
            </div>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Grid container style={{ height: "100%" }}>
              <Grid item xs={12} style={{ height: "100%" }}>
                <div
                  style={{
                    padding: 16,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                  }}
                >
                  <h2
                    style={{
                      textAlign: "center",
                      flex: 1,
                      margin: 0,
                    }}
                  >
                    Staged For {details.job_name} - {details.projectid}
                  </h2>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <DeliverFromStaging
              stagedTransactions={stagedTransactions}
              setStagedTransactions={setStagedTransactions}
              deliverItems={deliverItems}
              previewModalOpen={previewModalOpen}
              setPreviewModalOpen={setPreviewModalOpen}
            />
          </Grid>
        </Grid>
      ) : (
        <div style={{ height: "100%" }}>
          <div>Loading...</div>
        </div>
      )}
    </PageContent>
  );
};

export default StagedTransaction;
