import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  Checkbox,
  TextareaAutosize,
  TextField,
  useMediaQuery,
  useTheme,
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  DialogContentText,
  Link,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import InventoryTracking from "../../../api/InventoryTracking";
import { AuthContext } from "../../../context/AuthContext";
import { TopNavContext } from "../../../context/TopNavContext";
import PageContent from "../../../component/PageContent";
import PrintableQRCode from "../../../component/PrintableQRCode";
import { SnackAlertContext } from "../../../context/SnackAlertContext";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalShipping } from "@mui/icons-material";
import momentPhoenix from "../../../helpers/momentPhoenix";

const VehicleDetails = () => {
  const { id } = useParams();
  const { authToken } = useContext(AuthContext);
  const { setPageName } = useContext(TopNavContext);
  const [details, setDetails] = useState(null);
  const [items, setItems] = useState([]);
  const [confirmDeliveredModal, setConfirmDeliveredModal] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [loading, setLoading] = useState(false);
  const { openSnackMessage } = useContext(SnackAlertContext);
  const navigate = useNavigate();

  const [activities, setActivities] = useState([]);
  const [startDate, setStartDate] = useState(
    momentPhoenix().startOf("year").format("MM/DD/YYYY")
  );
  const [endDate, setEndDate] = useState(momentPhoenix().format("MM/DD/YYYY"));
  const selectStartDate = (val) => {
    setStartDate(val);
  };
  const selectEndDate = (val) => {
    setEndDate(val);
  };

  const getVehicleDetails = async () => {
    try {
      setLoading(true);
      const response = await InventoryTracking.get(`/vehicles/${id}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      setDetails(response.data.vehicle);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getVehicleItems = async () => {
    try {
      setLoading(true);
      const response = await InventoryTracking.get(`/vehicles/${id}/items`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      setItems(response.data.items);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getVehicleHistory = async (controller) => {
    try {
      let beginningOfStartDate = momentPhoenix(startDate)
        .startOf("day")
        .format("MM/DD/YYYY");
      let endOfEndDate = momentPhoenix(endDate)
        .endOf("day")
        .format("MM/DD/YYYY");

      let data = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      if (controller) {
        data.signal = controller.signal;
      }

      const response = await InventoryTracking.get(
        `/vehicles/${id}/activities?startDate=${momentPhoenix(
          beginningOfStartDate
        ).toDate()}&endDate=${momentPhoenix(endOfEndDate).toDate()}`,
        data
      );
      setActivities(response.data.activities);
    } catch (error) {
      console.log(error);
    }
  };

  const deliverItems = async () => {
    try {
      setLoading(true);

      let data = { loadedOnTruckItems: items };

      const response = await InventoryTracking.post(
        `/vehicles/${id}/delivered`,
        data,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      console.log("DELIVERED!", data);
      openSnackMessage("success", "Item delivery confirmed");
      setConfirmDeliveredModal(false);
      getVehicleItems();
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.message);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", "Internal Server Error");
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getVehicleDetails();
    getVehicleItems();
  }, []);

  useEffect(() => {
    const controller = new AbortController();

    getVehicleHistory(controller);
    return () => {
      controller.abort(); // abort api call on unmount for cleanup
    };
  }, [startDate, endDate]);

  return (
    <>
      <PageContent>
        <h2 style={{ textAlign: "center" }}>Truck Details</h2>

        {details ? (
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {!isSmallScreen && (
                <PrintableQRCode
                  url={`${window.location.host}/vehicles/${id}`}
                  displaySize={100}
                  printSize={100}
                  topText={details.nick_name}
                  subtext={details.number}
                />
              )}
            </div>
            <h3>Truck {details.id}</h3>
            <div style={{}}>Name: {details.nick_name}</div>
            <div style={{}}>Description: {details.description}</div>
            <div style={{}}>Number: {details.number}</div>
            <div style={{}}>Make: {details.make}</div>

            <div style={{}}>Model: {details.model}</div>
            <div style={{}}>Year: {details.year}</div>
            <div style={{}}>VIN: {details.vin}</div>
            <div style={{}}>Plate Number: {details.plate_number}</div>

            <div
              style={{
                position: "relative",
                flexDirection: "row",
                display: isSmallScreen ? "column" : "row",
                marginTop: 20,
                display: "flex",
                flexDirection: "row",
              }}
            >
              <h3
                style={{
                  //background: "#d2d2d2",
                  flex: 1,
                  margin: 0,
                }}
              >
                Items Loaded
              </h3>
              {/* <Button
              disabled={loading}
              variant="contained"
              onClick={() => {
                setConfirmDeliveredModal(true);
              }}
            >
              Delivered
            </Button> */}
              <Button
                size="small"
                style={{
                  height: 50,
                  marginTop: 16,
                }}
                startIcon={<LocalShipping />}
                variant="outlined"
                onClick={() => {
                  setConfirmDeliveredModal(true);
                }}
              >
                Delivered
              </Button>
            </div>
            <TableContainer component={Box}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Description</TableCell>
                    <TableCell>From</TableCell>
                    <TableCell>UOM</TableCell>
                    <TableCell>Quantity</TableCell>
                    <TableCell>Transaction ID</TableCell>
                    <TableCell>Job</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {items &&
                    items.map((item, index) => (
                      <TableRow
                        // onClick={() =>
                        //   //navigate(`/daily-stockouts/${grab.id}`)
                        // }
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell>{item.description}</TableCell>

                        <TableCell>{item.material_origin_key}</TableCell>
                        <TableCell>{item.uom}</TableCell>
                        <TableCell>{item.transaction_total_quantity}</TableCell>
                        <TableCell>{item.transaction_id}</TableCell>
                        <TableCell>
                          <Link
                            sx={{ cursor: "pointer" }}
                            onClick={() => {
                              navigate(
                                `/jobs/${item.project_backlog_id}/loaded`
                              );
                            }}
                          >
                            {item.job_id}: {item.job_name}
                          </Link>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ) : (
          <div style={{ height: "100%" }}>
            <div>Loading...</div>
          </div>
        )}
        <Dialog
          // fullScreen={fullScreen}
          open={confirmDeliveredModal}
          onClose={() => {
            setConfirmDeliveredModal(false);
          }}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <DialogTitle>Confirm delivery?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Make sure all items are on the truck before it leaves the yard
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setConfirmDeliveredModal(false);
              }}
            >
              Back
            </Button>
            <Button
              disabled={loading}
              onClick={() => {
                deliverItems();
              }}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </PageContent>
      <Box>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: 60,
            alignItems: "center",
          }}
        >
          <h3
            style={{
              flex: 1,
            }}
          >
            History
          </h3>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <MobileDatePicker
              label="From"
              inputFormat="MM/DD/YYYY"
              value={startDate}
              onChange={selectStartDate}
              renderInput={(params) => <TextField {...params} />}
            />
            <MobileDatePicker
              label="To"
              inputFormat="MM/DD/YYYY"
              value={endDate}
              onChange={selectEndDate}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </div>
        <TableContainer component={Box}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Item</TableCell>
                <TableCell>Quantity</TableCell>
                <TableCell>Transaction ID</TableCell>
                <TableCell>Transferred To</TableCell>
                <TableCell>Transferred By</TableCell>
                <TableCell>Job</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {activities &&
                activities.map((activity, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell>
                      {momentPhoenix(activity.created_on).format(
                        "MM/DD/YY h:mm a"
                      )}
                    </TableCell>
                    <TableCell>{activity.description}</TableCell>
                    <TableCell
                      style={{
                        color: activity.quantity < 0 ? "red" : "green",
                      }}
                    >
                      {activity.quantity < 0 ? "" : "+"}
                      {activity.quantity}
                    </TableCell>
                    <TableCell>{activity.transaction_id}</TableCell>
                    <TableCell>
                      {activity.transferred_to
                        ? activity.transferred_to
                        : "TRUCK"}
                    </TableCell>
                    <TableCell>{activity.transferred_by}</TableCell>
                    <TableCell>
                      <Link
                        sx={{ cursor: "pointer" }}
                        onClick={() => {
                          navigate(`/jobs/${activity.project_backlog_id}`);
                        }}
                      >
                        {activity.job_id}: {activity.job_name}
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default VehicleDetails;
