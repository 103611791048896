import { useContext, useEffect, useState } from "react";
import InventoryTracking from "../../../../../api/InventoryTracking";
import { useNavigate } from "react-router";
import { useTheme } from "@emotion/react";
import { AuthContext } from "../../../../../context/AuthContext";
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { SnackAlertContext } from "../../../../../context/SnackAlertContext";
import LoadedTrucks from "./LoadedTrucks";

const LoadedOnTruckTab = ({ jobId }) => {
  const [trucks, setTrucks] = useState([]);
  const [previewModalOpen, setPreviewModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { authToken } = useContext(AuthContext);
  const [confirmDeliveredModal, setConfirmDeliveredModal] = useState({
    open: false,
    vehicle: null,
  });

  const { openSnackMessage } = useContext(SnackAlertContext);
  let navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const getLoadedTrucksByJob = async () => {
    try {
      const response = await InventoryTracking.get(
        `/jobs/${jobId}/loaded-on-truck`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      // Set All Item Transaction Quantity to Zero
      let trucksCopy = response.data.trucks;
      for (let truck of trucksCopy) {
        for (let item of truck.items) {
          item.transaction_quantity = 0;
        }
      }
      console.log("TRUCKS", trucksCopy);
      setTrucks(trucksCopy);
    } catch (error) {
      console.log(error);
    }
  };

  const handleVehicleSelected = async (vehicle_id) => {
    try {
      setIsLoading(true);

      const getVehicleDetails = await InventoryTracking.get(
        `/vehicles/${vehicle_id}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      const getVehicleItems = await InventoryTracking.get(
        `/vehicles/${vehicle_id}/items`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      let vehicle = {
        ...getVehicleDetails.data.vehicle,
        items: getVehicleItems.data.items,
      };

      setConfirmDeliveredModal({
        open: true,
        vehicle: vehicle,
      });
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.message);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", "Internal Server Error");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const deliverItems = async () => {
    try {
      setIsLoading(true);
      console.log("MODAL DATA", confirmDeliveredModal);
      let vehicle_id = confirmDeliveredModal.vehicle.id;

      let data = { loadedOnTruckItems: confirmDeliveredModal.vehicle.items };

      const response = await InventoryTracking.post(
        `/vehicles/${vehicle_id}/delivered`,
        data,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      console.log("DELIVERED!", data);
      openSnackMessage("success", "Item delivery confirmed");
      setConfirmDeliveredModal({ open: false, vehicle: null });
      getLoadedTrucksByJob();
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.message);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", "Internal Server Error");
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getLoadedTrucksByJob();
  }, []);

  return (
    <div>
      <Dialog
        fullScreen={isSmallScreen}
        scroll="body" // Alows scrolling the table
        open={confirmDeliveredModal.open && confirmDeliveredModal.vehicle}
        onClose={() => {
          setConfirmDeliveredModal({
            open: false,
            vehicle: null,
          });
        }}
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <DialogTitle>Confirm delivery?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Make sure all items are on the truck before it leaves the yard
          </DialogContentText>
          <TableContainer component={Box}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Description</TableCell>
                  <TableCell>From</TableCell>
                  <TableCell>UOM</TableCell>
                  <TableCell>Quantity</TableCell>
                  <TableCell>Transaction ID</TableCell>
                  <TableCell>Job</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {confirmDeliveredModal &&
                  confirmDeliveredModal.vehicle &&
                  confirmDeliveredModal.vehicle.items &&
                  confirmDeliveredModal.vehicle.items.map((item, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell>{item.description}</TableCell>
                      <TableCell>{item.material_origin_key}</TableCell>
                      <TableCell>{item.uom}</TableCell>
                      <TableCell>{item.transaction_total_quantity}</TableCell>
                      <TableCell>{item.transaction_id}</TableCell>
                      <TableCell>
                        <Link
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            // navigate(`/jobs/${item.project_backlog_id}/loaded`);
                          }}
                        >
                          {item.job_id}: {item.job_name}
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={isLoading}
            onClick={() => {
              setConfirmDeliveredModal({
                open: false,
                vehicle: null,
              });
            }}
          >
            Back
          </Button>
          <Button
            disabled={isLoading}
            onClick={() => {
              deliverItems();
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <LoadedTrucks
        isLoading={isLoading}
        trucks={trucks}
        setTrucks={setTrucks}
        previewModalOpen={previewModalOpen}
        setPreviewModalOpen={setPreviewModalOpen}
        onTruckClick={(vehicleId, vehicle) => {
          handleVehicleSelected(vehicleId);
        }}
      />
    </div>
  );
};

export default LoadedOnTruckTab;
