import { useContext, useEffect, useState } from "react";
import { Box, Tab, Tabs, useMediaQuery } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import PageContent from "../../../component/PageContent";
import { useTheme } from "@emotion/react";
import StockoutsAndPullsNeedToPost from "./StockoutsAndPullsNeedToPost";
import Pos from "./Pos";

const NeedToPost = () => {
  const navigate = useNavigate();
  const { section } = useParams();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const defaultTab = (sectionParam) => {
    const tabParamPairings = [
      { param: "stock-outs", tab: "STOCK OUTS" },
      { param: "pull-requests", tab: "PULL REQUESTS" },
    ];
    const validParam = tabParamPairings.find(
      (tab) => tab.param === sectionParam
    );
    return validParam ? validParam.tab : "POS";
  };
  const [activeTab, setActiveTab] = useState(defaultTab(section));

  return (
    <PageContent>
      <div
        style={{
          position: "relative",
          flexDirection: "row",
          display: useMediaQuery(theme.breakpoints.down("sm"))
            ? "column"
            : "row",
          marginTop: 16,
        }}
      >
        <h2
          style={{
            textAlign: "center",
          }}
        >
          Need To Post
        </h2>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            marginLeft: 0,
            marginRight: 0,
            marginBottom: "16px",
          }}
        >
          <Tabs
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            // textColor="#fff"
            // indicatorColor="#fff"
            value={activeTab}
            onChange={(event, newValue) => {
              setActiveTab(newValue);
            }}
          >
            <Tab label="POs" value="POS" />
            <Tab label="Stock Outs" value="STOCK OUTS" />
          </Tabs>
        </Box>
        <div hidden={activeTab !== "POS"}>
          <Pos />
        </div>
        <div hidden={activeTab !== "STOCK OUTS"}>
          <StockoutsAndPullsNeedToPost />
        </div>
      </div>
    </PageContent>
  );
};

export default NeedToPost;
