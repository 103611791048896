import React, { useContext, useEffect, useState } from "react";
import { Box } from "@mui/material";
import PoList from "../POs/PoList";

const PendingPOs = () => {
  return (
    <Box>
      <PoList hideStatusFilter={true} defaultStatus="RECEIVED" />
    </Box>
  );
};

export default PendingPOs;
