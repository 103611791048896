import { Card, Typography, Box } from "@mui/material";
import momentPhoenix from "../../../../../../helpers/momentPhoenix";

const ReportDetails = ({ detail }) => {
  return (
    <>
      <Card sx={{ padding: 1 }}>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="overline">
            reported on: {momentPhoenix(detail.created_on).format("MM/DD/yyyy")}
          </Typography>
          {detail.responsible_user_name && (
            <Typography variant="overline">
              Damaged By: {detail.responsible_user_name}
            </Typography>
          )}
          {detail.notes && (
            <Typography variant="overline">notes: {detail.notes}</Typography>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "1rem",
            overflowX: "auto",
          }}
        >
          {detail.image_urls.map((src, i) => (
            <img key={src + i} src={src} width="150px" height="150px" />
          ))}
        </Box>
      </Card>
    </>
  );
};
export default ReportDetails;
