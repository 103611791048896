import Tile from "./Tile";
import { Box, Grid, useMediaQuery } from "@mui/material";
import useBadgeCounts from "./useBadgeCounts";
import { useTheme } from "@emotion/react";

const HomePage = () => {
  const { counts, loading } = useBadgeCounts();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  console.log(counts);

  let totalStaged = 0;
  if (counts && counts.stagedJobs) totalStaged += counts.stagedJobs;
  if (counts && counts.loadedTrucks) totalStaged += counts.loadedTrucks;

  const tiles = [
    {
      title: "stock outs",
      path: "/daily-stockouts",
      // count: loading ? 0 : counts.stockOuts,
    },
    {
      title: "Pull Requests",
      path: "/pull-requests",
      // count: loading ? 0 : counts.pullRequests,
    },
    {
      title: "Inventory",
      path: "/inventory",
      count: 0,
    },
    {
      title: "Staged",
      path: "/staged",
      count: loading && totalStaged ? 0 : totalStaged,
    },
    {
      title: "P.O.'S",
      path: "/pending-pos",
      count: loading ? 0 : counts.pendingPOs,
    },
    {
      title: "Committed",
      path: "/committed",
      count: loading ? 0 : counts.committed,
    },
    { title: "Job Material History", path: "/jobs", count: 0 },
    {
      title: "Returns",
      path: "/return",
      count: loading ? 0 : counts.returns,
    },
  ];

  let needToPostCount = 0;
  if (counts && counts.stockOuts) {
    needToPostCount += counts.stockOuts;
  }
  if (counts && counts.pullRequests) {
    needToPostCount += counts.pullRequests;
  }
  if (counts && counts.receivedPOs) {
    needToPostCount += counts.receivedPOs;
  }

  return (
    <Box
      sx={{
        flexGrow: 1,
      }}
    >
      {/* <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          marginTop: 10,
        }}
      >
        {tiles.map((t, index) => (
          <Box
            key={index}
            sx={{
              flexBasis: "50%",
              display: "flex",
              justifyContent: index % 2 == 0 ? "end" : "start",
              padding: "8px",
            }}
          >
            <Tile title={t.title} path={t.path} badgeCount={t.count} />
          </Box>
        ))}
      </Box> */}
      <Box
        sx={{ display: "flex", justifyContent: "center", paddingTop: "50px" }}
      >
        <Grid
          container
          spacing={2}
          padding={1}
          sx={{ width: isSmallScreen ? "100%" : "60%" }}
        >
          {tiles.map((t, index) => (
            <Grid item xs={6}>
              <Tile title={t.title} path={t.path} badgeCount={t.count} />
            </Grid>
          ))}
          <Grid item xs={12}>
            <Tile
              title={"Need To Post"}
              path={"/need-to-post"}
              badgeCount={needToPostCount}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
export default HomePage;
