import { useTheme } from "@emotion/react";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import PulledItemCard from "../../../component/PulledItemCard";
import CategorySearch from "../../../component/CategorySearch";
import { AuthContext } from "../../../context/AuthContext";
import InventoryTracking from "../../../api/InventoryTracking";
import { SnackAlertContext } from "../../../context/SnackAlertContext";
import escapeCharacters from "../../../helpers/escapeCharacters";
import { Edit } from "@mui/icons-material";

const EditPulledItems = ({
  details,
  onUpdatePulledItems,
  isApprovedOrRejected,
  isPullRequest,
}) => {
  const { authToken, userBranch, isMaterialAppAdmin } = useContext(AuthContext);
  const [addMore, setAddMore] = useState(false);
  const [category, setCategory] = useState("");
  const [categorySearch, setCategorySearch] = useState("");
  const [categorySearchResults, setCategorySearchResults] = useState([]);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [qrScan, setQrScan] = useState("");
  const [scannedItem, setScannedItem] = useState(null);
  const [items, setItems] = useState(details.items);
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { openSnackMessage } = useContext(SnackAlertContext);

  const updatePullItems = async () => {
    console.log("UPDATE PULL ITEMS", items);
    try {
      setIsLoading(true);
      console.log("ITEMS", items);
      await InventoryTracking.put(
        `/pulls/${details.id}/items`,
        {
          items: items,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setOpen(false);
      onUpdatePulledItems();
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const createGrabItem = (item, origin, quantity) => {
    let grabItem = null;
    if (origin === "INVENTORY") {
      grabItem = {
        quantity: quantity,
        material_origin_key: origin,
        // material_id: item.inventoryId, //reference to: inventory_existence.id
        material_id: item.itemId, // material_id now corresponds with the lookup_inventory_master.id
        item_description: item.description,
        uom: item.uom,
        image_url: item.image_url,
      };
    } else if (origin === "BONEYARD") {
      grabItem = {
        quantity: quantity,
        material_origin_key: origin,
        material_id: item.id, //reference to boneyard_inventory.id (this remains unchanged)
        item_description: item.item,
        uom: item.uom_description,
        image_url: item.image_url,
      };
    } else if (origin === "COMMITTED") {
      grabItem = {
        quantity: quantity,
        material_origin_key: origin,
        // material_id: item.id, //reference to material_tracking_committed.id
        material_id: item.item_id, // material_id now corresponds with the lookup_inventory_master.id
        item_description: item.item_description,
        uom: item.uom,
        image_url: item.image_url,
      };
    } else if (origin === "STAGED") {
      grabItem = {
        quantity: item.quantity,
        material_origin_key: origin,
        material_id: item.item_id, // material_id now corresponds with the lookup_inventory_master.id
        item_description: item.description,
        uom: item.uom,
        image_url: item.image_url,
        staged_id: item.staged_id,
        // original_staged_transaction: item.txn,
        reference_id: item.txn,
        staged_source: item.material_origin_key,
      };
    }

    return grabItem;
  };

  const addItem = async (item, type) => {
    console.log("addItem ", item, type);
    try {
      setIsLoading(true);
      let pulledItem;
      let itemId;
      switch (type) {
        case "Inventory":
          itemId = item.inventoryId;
          break;
        case "Committed":
          itemId = item.id;
          break;
        case "Boneyard":
          itemId = item.id;
          break;

        default:
          break;
      }

      // Get Ote, Detao;
      const response = await InventoryTracking.get(`/${type}/${itemId}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      let material = response.data.material;
      console.log("MATERIAL: ", material);

      // Set Modal Data
      if (type === "Inventory") {
        pulledItem = createGrabItem(material, "INVENTORY", 1);
      } else if (type === "Boneyard") {
        pulledItem = createGrabItem(material, "BONEYARD", 1);
      } else if (type === "Committed") {
        if (details.project_backlog_id !== item.project_backlog_id) {
          openSnackMessage(
            "warning",
            `
                    This material is committed to ${item.job_name} 
                    (${item.job_id}). You are currently stocking out
                    materials committed to
                    ${details.job_name} (${details.job_id}).
                    You must remove the committed materials before
                    stocking out materials committed to another job.
                  `
          );
          return;
        } else {
          pulledItem = createGrabItem(material, "COMMITTED", 1);
        }
      }
      //   const addPulledItem = await InventoryTracking.post(
      //     `/stockouts/${details.id}/items`,
      //     pulledItem,
      //     {
      //       headers: {
      //         Authorization: `Bearer ${authToken}`,
      //       },
      //     }
      //   );
      //   openSnackMessage("success", "Item Added!");
      //   //   getGrabbedDetails();
      setItems((prev) => [...prev, pulledItem]);
      setScannedItem(null);
      setQrScan("");
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.message);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", "Internal Server Error");
      }
    } finally {
      setIsLoading(false);
      setScannedItem(null);
      setQrScan("");
    }
  };

  const handleCategorySearch = async (controller) => {
    try {
      if (!categorySearch) {
        setCategorySearchResults([]);
        return;
      }
      setIsLoading(true);
      let headers = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      if (controller) {
        headers.signal = controller.signal;
      }
      const repo = getEndpoint(category);
      const query = escapeCharacters(categorySearch);
      const response = await InventoryTracking.get(
        `${repo}?search=${query}&location=${userBranch}&pageSize=20&page=${page}`,
        headers
      );
      const data = getResponseData(category, response);
      console.log(data);
      setIsLoading(false);
      setCategorySearchResults([...data.items]);
      setPages(data.pages);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const getEndpoint = (category) => {
    switch (category) {
      case "Inventory":
        return "/inventory";
      case "Boneyard":
        return "/boneyard";
      case "Committed":
        return "/committed";
      default:
        break;
    }
  };
  const getResponseData = (category, apiResponse) => {
    switch (category) {
      case "Inventory":
        return {
          items: apiResponse.data.materials,
          pages: apiResponse.data.pages,
        };
      case "Boneyard":
        return {
          items: apiResponse.data.materials,
          pages: apiResponse.data.pages,
        };
      case "Committed":
        return {
          items: apiResponse.data.committed.committed,
          pages: apiResponse.data.committed.pages,
        };
      default:
        break;
    }
  };

  const getItemDetailFromScan = async (scannedUrl) => {
    try {
      setIsLoading(true);

      if (scannedUrl.includes("/jobs/")) {
        let jobId = scannedUrl.split("/jobs/")[1];
      } else {
        // Parse Item QR Code URL
        let itemUri;
        let itemId;
        if (scannedUrl.includes("/inventory/")) {
          itemUri = "inventory";
          itemId = scannedUrl.split("/inventory/")[1];
        } else if (scannedUrl.includes("/boneyard/")) {
          itemUri = "boneyard";
          itemId = scannedUrl.split("/boneyard/")[1];
        } else if (scannedUrl.includes("/committed/")) {
          itemUri = "committed";
          itemId = scannedUrl.split("/committed/")[1];
        }

        console.log(`getting item /${itemUri}/${itemId}`);
        // Get Item Details
        const response = await InventoryTracking.get(`/${itemUri}/${itemId}`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });

        let material = response.data.material;
        console.log(material);

        // Set Modal Data
        if (scannedUrl.includes("/inventory/")) {
          setScannedItem({
            source: itemUri,
            details: material,
            name: material.description,
            image: material.image_url,
            uom: material.uom,
            quantity: 1,
          });
        } else if (scannedUrl.includes("/boneyard/")) {
          setScannedItem({
            source: itemUri,
            details: material,
            name: material.item,
            image: material.image_url,
            uom: material.uom,
            quantity: 1,
          });
        } else if (scannedUrl.includes("/committed/")) {
          setScannedItem({
            source: itemUri,
            details: material,
            name: material.item_description,
            image: material.image_url,
            uom: material.uom,
            quantity: 1,
          });
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    setTimeout(() => {
      handleCategorySearch(controller);
      setPage(1);
    }, 300);
    return () => {
      controller.abort();
    };
  }, [categorySearch, category]);

  useEffect(() => {
    console.log("qrScan", qrScan);
    if (qrScan) {
      // Get Item Details from qr scan link
      // Add to grabs
      getItemDetailFromScan(qrScan);
    }
  }, [qrScan]);

  useEffect(() => {
    console.log("ITEMS", items);
  }, [items]);

  return (
    <Box>
      <Button
        variant="outlined"
        onClick={() => {
          setOpen(true);
        }}
        startIcon={<Edit />}
      >
        Edit Pulled Items
      </Button>
      <Dialog
        fullScreen={isSmallScreen}
        fullWidth
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <DialogTitle>Update Items</DialogTitle>
        <DialogContent>
          {items &&
            items.map((item, index) => (
              <PulledItemCard
                key={index}
                showRequested={details.is_stage_request}
                itemName={item.item_description}
                source={item.material_origin_key}
                qtyReq={item.requested_quantity}
                qtyPulled={item.quantity}
                uom={item.uom}
                onQtyChange={(qty) => {
                  console.log("Quantity Updated", qty);
                  // updateQuantity(item.id, qty);
                  let itemsCopy = [...items];
                  itemsCopy[index].quantity = qty;
                  setItems(itemsCopy);
                }}
                onReqQtyChange={(qty) => {
                  // updateQuantityRequested(item.id, qty);
                  let itemsCopy = [...items];
                  itemsCopy[index].requested_quantity = qty;
                  setItems(itemsCopy);
                }}
                onRemove={() => {
                  let itemsCopy = [...items];
                  itemsCopy.splice(index, 1);
                  setItems(itemsCopy);
                }}
                //   disableDelete={
                //     (!isPullRequest && !isMaterialAppAdmin()) ||
                //     isApprovedOrRejected ||
                //     details.status_description
                //       ? true
                //       : (isPullRequest && !isMaterialAppAdmin()) || isApprovedOrRejected
                //       ? true
                //       : false
                //   }
                //   disableEdit={
                //     isApprovedOrRejected ||
                //     (!isPullRequest && !isMaterialAppAdmin()) ||
                //     details.status_description
                //   }
                enableEditRequestedQty={
                  !isApprovedOrRejected && isPullRequest && isMaterialAppAdmin()
                }
              />
            ))}
          {isMaterialAppAdmin ? ( // (isMaterialAppAdmin() || details.is_stage_request)
            <div style={{ marginTop: 16 }}>
              {!addMore ? (
                <Button
                  variant="outlined"
                  fullWidth
                  onClick={() => setAddMore(true)}
                >
                  + MATERIAL
                </Button>
              ) : (
                <div>
                  <CategorySearch
                    categoryOptions={["Inventory", "Committed", "Boneyard"]}
                    categoryValue={category}
                    searchValue={categorySearch}
                    results={categorySearchResults}
                    handleResultClick={(item) => {
                      addItem(item, category);
                      setCategorySearch("");
                    }}
                    handleSearch={(e) => setCategorySearch(e.target.value)}
                    handleSelect={(e) => setCategory(e.target.value)}
                    onQrCodeScan={(result) => {
                      setQrScan(result);
                    }}
                  />
                </div>
              )}
            </div>
          ) : null}
        </DialogContent>
        <DialogActions>
          <LoadingButton
            loading={isLoading}
            onClick={() => {
              setOpen(false);
            }}
          >
            Cancel
          </LoadingButton>
          <LoadingButton loading={isLoading} onClick={updatePullItems}>
            Update
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default EditPulledItems;
