import api from "../../../../../api/InventoryTracking";
import { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../../../../context/AuthContext";
import RequestsView from "./RequestsView";
import useAssetManagers from "../../../../../customHooks/useAssetManagers";

const RequestsComponent = () => {
  const { authToken } = useContext(AuthContext);
  const { isAssetManager } = useAssetManagers();
  const [isLoading, setIsloading] = useState(false);
  const [error, setError] = useState(null);

  const [isDamagedModal, setIsDamagedModal] = useState(false);
  const [damageReportModal, setDamageReportModal] = useState(false);

  const [assRequests, setAssRequests] = useState([]);

  const getTransferRequests = async () => {
    try {
      setIsloading(true);
      const res = await api.get(`/assets/transfer-requests`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      console.log(res.data);
      setAssRequests(res.data.requests);
    } catch (error) {
      console.log(error);
    } finally {
      setIsloading(false);
    }
  };

  const handleAcceptReject = async (
    boolean,
    requestId,
    assetId,
    assetType,
    assetName,
    userId,
    userFullName
  ) => {
    try {
      setIsloading(true);
      const header = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      const body = {
        boolean,
        assetId,
        assetType,
        assetName,
        userId,
        userFullName,
      };

      const res = await api.put(
        `/assets/transfer-requests/${requestId}`,
        body,
        header
      );
      console.log(res);
      getTransferRequests();
    } catch (error) {
      console.log(error);
    } finally {
      setIsloading(false);
    }
  };

  useEffect(() => {
    getTransferRequests();
  }, []);

  return (
    <>
      <RequestsView
        data={assRequests}
        isAssetManager={isAssetManager}
        isLoading={isLoading}
        error={error}
        toggleModals={() => {}}
        handleAcceptReject={handleAcceptReject}
      />
    </>
  );
};
export default RequestsComponent;
