import { useContext, useEffect, useState } from "react";
import { Box, Tab, Tabs, useMediaQuery } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import PageContent from "../../../component/PageContent";
import { useTheme } from "@emotion/react";
import Staged from "./Staged";
import Vehicles from "./Vehicles";

const StagedAndTrucks = () => {
  const navigate = useNavigate();
  const { section } = useParams();
  const { authToken } = useContext(AuthContext);
  const [stockouts, setStockouts] = useState([]);
  const [searchStockout, setSearchStockout] = useState("");

  const [pullRequests, setPullrequests] = useState([]);
  const [searchPullRequest, setSearchPullRequest] = useState("");
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const defaultTab = (sectionParam) => {
    if (!sectionParam) return "STAGED";
    const tabParamPairings = [
      { param: "staged", tab: "STAGED" },
      { param: "loaded-trucks", tab: "LOADED TRUCKS" },
    ];
    const validParam = tabParamPairings.find(
      (tab) => tab.param === sectionParam
    );
    return validParam ? validParam.tab : "STAGED";
  };
  const [activeTab, setActiveTab] = useState(defaultTab(section));

  useEffect(() => {
    if (section) {
      console.log("section", section);
      setActiveTab(defaultTab(section));
    }
  }, [section]);

  return (
    <PageContent>
      <div
        style={{
          position: "relative",
          flexDirection: "row",
          display: useMediaQuery(theme.breakpoints.down("sm"))
            ? "column"
            : "row",
          marginTop: 16,
        }}
      >
        <h2
          style={{
            textAlign: "center",
          }}
        >
          Staged
        </h2>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            marginLeft: 0,
            marginRight: 0,
            marginBottom: "16px",
          }}
        >
          <Tabs
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            // textColor="#fff"
            // indicatorColor="#fff"
            value={activeTab}
            onChange={(event, newValue) => {
              // setActiveTab(newValue);
              switch (newValue) {
                case "STAGED":
                  navigate(`/staged/staged`, { replace: true });
                  break;
                case "LOADED TRUCKS":
                  navigate(`/staged/loaded-trucks`, { replace: true });
                  break;
              }
            }}
          >
            <Tab label="Staged" value="STAGED" />
            <Tab label="Loaded Trucks" value="LOADED TRUCKS" />
          </Tabs>
        </Box>

        <div hidden={activeTab !== "STAGED"}>
          <Staged />
        </div>
        <div hidden={activeTab !== "LOADED TRUCKS"}>
          <Vehicles />
        </div>
      </div>
    </PageContent>
  );
};

export default StagedAndTrucks;
