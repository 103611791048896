import { useContext, useEffect, useState } from "react";
import InventoryTracking from "../../../../../api/InventoryTracking";
import ListItem from "../../../../../component/ListItem";
import { useNavigate } from "react-router";
import { useTheme } from "@emotion/react";
import { AuthContext } from "../../../../../context/AuthContext";

const CommittedTab = ({ jobId }) => {
  const [committedItems, setCommittedItems] = useState([]);
  const { authToken } = useContext(AuthContext);
  let navigate = useNavigate();
  const theme = useTheme();

  const getCurrentCommitted = async () => {
    try {
      const response = await InventoryTracking.get(`/jobs/${jobId}/committed`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      console.log("committed", response.data);
      setCommittedItems(response.data.committed);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getCurrentCommitted();
  }, []);

  return (
    <div>
      <div style={{}}>
        {committedItems &&
          committedItems.map((committed, index) => {
            let details = [];
            details.push(
              `Committed to: ${committed.job_name} (${committed.job_id})`
            );
            details.push(
              `Internal Code: ${
                committed.internal_code ? committed.internal_code : ""
              }`
            );
            details.push(`Quantity: ${committed.quantity} ${committed.uom}`);

            details.push(`Location: ${committed.location}`);
            return (
              <ListItem
                key={index}
                title={committed.item_description}
                details={details}
                image={committed.image_url}
                onClick={() => {
                  navigate(`/committed/${committed.id}`);
                }}
              />
            );
          })}
      </div>
      {/* <CommittedTabHistory jobId={jobId} /> */}
    </div>
  );
};

export default CommittedTab;
