import api from "../api/InventoryTracking";
import { AuthContext } from "../context/AuthContext";
import { useState, useContext, useEffect } from "react";
import jwtDecode from "jwt-decode";

const useAssetManagers = () => {
  const { authToken, isMaterialAppAdmin } = useContext(AuthContext);
  const { id: userId } = jwtDecode(authToken);
  const [isAssetManager, setIsAssetManager] = useState(false);

  const isAdminAssetManager = (userId, allowedIds = []) => {
    return isMaterialAppAdmin() || allowedIds.includes(userId);
  };

  useEffect(() => {
    api
      .get("/assets/allowed-users", {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((res) => {
        console.log("Allowed IDs response", res);
        console.log(
          "Is Admin or Asset Mangr? ",
          isAdminAssetManager(userId, res.data.allowedIds)
        );
        return isAdminAssetManager(userId, res.data.allowedIds);
      })
      .then((bool) => {
        console.log("Boolean: ", bool);
        return setIsAssetManager(bool);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  console.log("isAssetManagerisAssetManager", isAssetManager);
  return { isAssetManager };
};
export default useAssetManagers;
