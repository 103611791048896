import { useContext, useEffect, useState } from "react";
import InventoryTracking from "../../../../../api/InventoryTracking";
import { useNavigate } from "react-router";
import { useTheme } from "@emotion/react";
import { AuthContext } from "../../../../../context/AuthContext";
import {
  Box,
  Button,
  Card,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  ListItem,
} from "@mui/material";
import { SnackAlertContext } from "../../../../../context/SnackAlertContext";
import momentPhoenix from "../../../../../helpers/momentPhoenix";

const PullRequestsTab = ({ jobId }) => {
  const [pullRequests, setPullRequests] = useState([]);

  const { authToken, isMaterialAppAdmin } = useContext(AuthContext);

  const { openSnackMessage } = useContext(SnackAlertContext);
  let navigate = useNavigate();
  const theme = useTheme();

  const getPullRequestHistoryByJob = async (controller) => {
    try {
      let data = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      const response = await InventoryTracking.get(
        `/jobs/${jobId}/pull-requests?status=all`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      console.log("Pull Requests", response.data);
      setPullRequests(response.data.pullRequests);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getPullRequestHistoryByJob();
  }, []);

  return (
    <Box>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        {isMaterialAppAdmin() && (
          <Button
            style={{
              height: 50,
              marginTop: 16,
            }}
            variant="outlined"
            onClick={() => {
              navigate(`/jobs/${jobId}/pull-requests/new`);
            }}
          >
            + New
          </Button>
        )}
      </div>
      <TableContainer component={Box}>
        <Table sx={{ minWidth: 350 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>By</TableCell>
              <TableCell>Job</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Confirmed</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pullRequests &&
              pullRequests.map((grab, index) => (
                <TableRow
                  component={ListItem}
                  onClick={() => navigate(`/pull-requests/${grab.id}`)}
                  key={index}
                  sx={{
                    "& td": { border: 0 },
                    cursor: "pointer",
                    "&:hover": {
                      background: "#5A5A5A",
                    },
                  }}
                >
                  <TableCell>
                    {momentPhoenix(grab.created_on).format(
                      "MM/DD/YY hh:mm A z"
                    )}
                  </TableCell>
                  <TableCell>{grab.user_full_name}</TableCell>
                  <TableCell>
                    {grab.job_name
                      ? `${grab.projectid} - ${grab.job_name}`
                      : "[UNLISTED]"}
                  </TableCell>
                  <TableCell>
                    {grab.status_description
                      ? grab.status_description
                      : "Pending"}
                  </TableCell>
                  <TableCell>
                    {grab.approved_on
                      ? "Posted"
                      : grab.rejected_on
                      ? "Rejected"
                      : "Pending"}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default PullRequestsTab;
