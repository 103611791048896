import { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Typography,
  useMediaQuery,
  Modal,
  IconButton,
  Stack,
} from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import CheckIcon from "@mui/icons-material/Check";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { AssetContext } from "../../../../../context/AssetContext";
import PrintableQRCode from "../../../../../component/PrintableQRCode";
import composePage from "../../HOCs/composePage";
import makePage from "../../HOCs/makePage";
import withConditionalFeedback from "../../HOCs/withConditionalFeedback";
import DeactivationModal from "./DeactivationModal";
import SoftDeleteModal from "./SoftDeleteModal";
import AssetNotesModal from "./AssetNotesModal";
import { useTheme } from "@emotion/react";
import momentPhoenix from "../../../../../helpers/momentPhoenix";

const Detail = ({ label, value }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "row" }}>
      <Typography
        variant="overline"
        sx={{ fontWeight: "600", lineHeight: "1.5em" }}
      >
        {label}:
      </Typography>
      <Typography
        variant="overline"
        sx={{ marginLeft: "1em", lineHeight: "1.5em" }}
      >
        {label === "replacement cost" ? `$${value}` : value}
      </Typography>
    </Box>
  );
};

const AssetDetailView = ({
  data,
  isInPossession,
  handleReturn,
  handleRequest,
  isAssetManager,
  handleDelete,
  updateAssetNotes,
}) => {
  console.log(data);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const { updateAssetAssignment, updateDamageReport } =
    useContext(AssetContext);

  const [buttonsClicked, setButtonsClicked] = useState({
    request: false,
    return: false,
    assign: false,
  });

  const clickOnce = (btnType, fn, ...args) => {
    let clicked = false;
    console.log("clicked: ", clicked);

    if (!clicked) {
      clicked = true;
      console.log("hello World");
      fn(...args);
      setButtonsClicked((state) => ({ ...state, [btnType]: true }));
    }
  };

  useEffect(() => {
    console.log(buttonsClicked);
  }, [buttonsClicked]);

  const [openNotesModal, setOpenNotesModal] = useState(false);
  const [openDeactivateModal, setOpenDeactivateModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const toggleNotesModal = (bool) => {
    if (typeof bool === "boolean") {
      setOpenNotesModal(bool);
    } else {
      setOpenNotesModal(!openNotesModal);
    }
  };

  const toggleDeactivateModal = (bool) => {
    if (typeof bool === "boolean") {
      setOpenDeactivateModal(bool);
    } else {
      setOpenDeactivateModal(!openDeactivateModal);
    }
  };

  const toggleDeleteModal = (bool) => {
    if (typeof bool === "boolean") {
      setOpenDeleteModal(bool);
    } else {
      setOpenDeleteModal(!openDeleteModal);
    }
  };

  const toolId = useParams().id;
  const toolType = useParams().type;

  const isCurrentlyAssigned =
    !!data.user_in_possession && !!data.took_possession_on;
  const assignedDate = isCurrentlyAssigned
    ? momentPhoenix(data.took_possession_on).format("MM/DD/YYYY")
    : null;
  const assignedTime = isCurrentlyAssigned
    ? momentPhoenix(data.took_possession_on).format("hh:mm a")
    : null;

  const handleAssign = () => {
    updateAssetAssignment({
      type: "SET-ASSET",
      value: {
        id: data.id,
        name: data.name,
        type: toolType,
      },
    });
    navigate("assign");
  };

  const unwantedDetails = [
    "doc",
    "picture",
    "id",
    "assign_type",
    "active",
    "docs",
    "assigned",
    "assigned_on",
    "assigned_by",
    "current_in_shop",
    "pic",
    "jobsite_location",
    "user_in_possession",
    "took_possession_on",
    "user_id_in_possession",
    "target_user_name",
    "external_picture",
  ];
  const detailFilter = (key) => !unwantedDetails.includes(key);

  return (
    <>
      <Box sx={styles.container}>
        <Typography variant="overline" sx={styles.toolName}>
          {data.name}
        </Typography>
        {!isSmallScreen && (
          <PrintableQRCode
            url={`${window.location.host}/assets/${toolType}/${toolId}`}
            displaySize={100}
            printSize={100}
            topText={data.number}
            subtext={data.name}
          />
        )}

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            margin: "1em 0 1em 0",
          }}
        >
          {!isCurrentlyAssigned ? (
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                variant="overline"
                textAlign={"center"}
                sx={{ color: "#06c916" }}
              >
                Available
              </Typography>
              {data.previous_assignee && (
                <Typography>
                  Last Used BY: {data.previous_assignee.toUpperCase()}
                </Typography>
              )}
            </Box>
          ) : (
            <>
              <Typography
                variant="overline"
                sx={{
                  fontWeight: "600",
                  color: "#064ec9",
                }}
              >
                {data.user_in_possession}
              </Typography>
              <Typography
                variant="overline"
                sx={{
                  marginLeft: ".5rem",
                }}
              >
                {assignedDate}
              </Typography>
            </>
          )}
        </Box>
        <Stack
          direction={{
            xs: "column-reverse",
            sm: "column-reverse",
            md: "row",
            lg: "row",
          }}
          spacing={{ xs: 5, sm: 5, md: 35 }}
        >
          <Box>
            {Object.keys(data)
              .filter(detailFilter)
              .map((key, index) => {
                if (data[key]) {
                  const label = key.replace("_", " ");
                  return <Detail key={index} label={label} value={data[key]} />;
                }
              })}
            <Button
              variant="outlined"
              size="small"
              sx={{ mt: "1rem" }}
              onClick={() => {
                navigate("damage-reports");
              }}
            >
              view damage reports
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {data.external_picture && (
              <img
                src={data.external_picture}
                alt="No Image Provided"
                width={isSmallScreen ? "100px" : "200px"}
                height={isSmallScreen ? "100px" : "200px"}
              />
            )}
          </Box>
        </Stack>

        <Box
          sx={{
            display: "flex",
            flexDirection: isSmallScreen ? "column" : "row",
            marginTop: "5em",
            marginBottom: "5em",
            gap: "1rem",
          }}
        >
          {isAssetManager && (
            <>
              <Button
                variant="contained"
                onClick={() => clickOnce("assign", handleAssign)}
                sx={{ minWidth: "10rem" }}
                startIcon={
                  <CheckIcon
                    sx={{ display: buttonsClicked.assign ? "" : "none" }}
                  />
                }
              >
                {isCurrentlyAssigned
                  ? "RE-ASSIGN"
                  : data.target_user_name
                  ? `Pending Tansfer To ${data.target_user_name}`
                  : "ASSIGN"}
              </Button>
              {isCurrentlyAssigned && (
                <Button
                  variant="contained"
                  sx={{ minWidth: "10rem" }}
                  onClick={() => clickOnce("return", handleReturn)}
                  startIcon={
                    <CheckIcon
                      sx={{ display: buttonsClicked.return ? "" : "none" }}
                    />
                  }
                >
                  Return
                </Button>
              )}
              <Button
                variant="outlined"
                sx={{ minWidth: "10rem" }}
                onClick={() => {
                  updateDamageReport({
                    type: "SET: ALL",
                    payload: {
                      name: data.user_in_possession,
                      id: data.user_id_in_possession,
                    },
                  });
                  navigate("file-damage-report");
                }}
              >
                Report Damage
              </Button>
            </>
          )}
          {!isAssetManager && (
            <>
              {isInPossession ? (
                <Button
                  variant="contained"
                  onClick={() => clickOnce("return", handleReturn)}
                  sx={{ minWidth: "10rem" }}
                  startIcon={
                    <CheckIcon
                      sx={{ display: buttonsClicked.return ? "" : "none" }}
                    />
                  }
                >
                  Return
                </Button>
              ) : (
                <Button
                  variant="contained"
                  onClick={() => clickOnce("request", handleRequest)}
                  sx={{ minWidth: "10rem" }}
                  startIcon={
                    <CheckIcon
                      sx={{ display: buttonsClicked.request ? "" : "none" }}
                    />
                  }
                >
                  {data.target_user_name
                    ? `Requested By ${data.target_user_name}`
                    : "Request"}
                </Button>
              )}
              <Button
                variant="outlined"
                sx={{ minWidth: "10rem" }}
                onClick={() => {
                  updateDamageReport({
                    type: "SET: ALL",
                    payload: {
                      name: data.user_in_possession,
                      id: data.user_id_in_possession,
                    },
                  });
                  navigate("file-damage-report");
                }}
              >
                Report Damage
              </Button>
            </>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            gap: "1.5rem",
          }}
        >
          <Box sx={{ marginLeft: "1.5rem" }}>
            <Button variant="outlined" onClick={toggleNotesModal}>
              Notes
            </Button>
          </Box>
          {isAssetManager && (
            <Box>
              <Button
                color="error"
                // sx={{ marginTop: isSmallScreen ? "4rem" : "0" }}
                onClick={toggleDeactivateModal}
                variant="outlined"
                size="small"
              >
                Deactivate
              </Button>
              <IconButton
                aria-label="delete"
                size="large"
                onClick={toggleDeleteModal}
              >
                <DeleteOutlineIcon />
              </IconButton>
            </Box>
          )}
        </Box>
      </Box>
      <DeactivationModal
        open={openDeactivateModal}
        toggleOpen={toggleDeactivateModal}
        handleDelete={handleDelete}
      />
      <SoftDeleteModal
        open={openDeleteModal}
        toggleOpen={toggleDeleteModal}
        handleDelete={handleDelete}
      />
      <AssetNotesModal
        open={openNotesModal}
        toggleOpen={toggleNotesModal}
        handleUpdateNotes={updateAssetNotes}
        assetNotes={data.notes}
      />
    </>
  );
};
export default composePage(makePage, withConditionalFeedback)(AssetDetailView);
const styles = {
  container: {
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  toolName: {
    fontWeight: "600",
    fontSize: "1.5em",
  },
  // buttonGroup: {
  //   display: "flex",
  //   flexDirection: isSmallScreen ? "row" : "column",
  //   marginTop: "5em",
  //   marginBottom: "3em",
  // },
};
