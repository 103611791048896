import { Card, Box, Typography, useMediaQuery, Button } from "@mui/material";
import { useTheme } from "@emotion/react";
import momentPhoenix from "../../../../../helpers/momentPhoenix";

const once = (fn) => {
  let clicked = false;
  return (...args) => {
    if (!clicked) {
      clicked = true;
      fn(...args);
    }
  };
};

const AssetCard = ({ asset, handleReturn }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Card
      sx={{
        width: isSmallScreen ? "100%" : "50%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        mb: "1em",
        p: 1,
      }}
    >
      <Box>
        <Typography
          variant="overline"
          sx={{ fontWeight: "600", fontSize: "1em" }}
        >
          Name:{" "}
          {asset.asset_name ? asset.asset_name : asset.name ? asset.name : ""}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          width: "100%",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "row", gap: "1em" }}>
          <Typography sx={{ fontSize: "1em" }} variant="overline">
            Checked out on:{" "}
          </Typography>
          <Typography
            sx={{ fontWeight: "600", fontSize: "1em" }}
            variant="overline"
          >
            {momentPhoenix(asset.date_accepted).format("MM/DD/YYYY")}
          </Typography>
        </Box>
        {/* assetType, assetId, assetName */}
        <Box>
          <Button
            size="small"
            variant="contained"
            onClick={once(() =>
              handleReturn(asset.asset_type, asset.asset_id, asset.asset_name)
            )}
            fullWidth
          >
            Send Return Request
          </Button>
        </Box>
      </Box>
    </Card>
  );
};
export default AssetCard;
