import {
  Autocomplete,
  Box,
  Button,
  Card,
  Divider,
  FormControl,
  InputAdornment,
  InputLabel,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../context/AuthContext";
import escapeCharacters from "../../../helpers/escapeCharacters";
import InventoryTracking from "../../../api/InventoryTracking";
import { useTheme } from "@emotion/react";
import { DarkLightModeContext } from "../../../context/DarkLightModeContext";
import { Add, Edit } from "@mui/icons-material";
import InventoryItem from "./InventoryItem";
import Vendor from "./Vendor";
import DeliveredItem from "./DeliveredItem";
import StagedItem from "./StagedItem";
import TruckItem from "./TruckItem";
import CommittedItem from "./CommittedItem";

const ReturnItemDetailsCard = ({
  isEditable,
  jobId,
  description,
  setDescription,
  quantity,
  setQuantity,
  returnFrom,
  setReturnFrom,
  returnFromId,
  setReturnFromId,
  returnTo,
  setReturnTo,
  inventoryItemId,
  setInventoryItemId,
  vendorId,
  setVendorId,
  uom,
  setUom,
  price,
  setPrice,
  onRemove,
}) => {
  const { isDarkMode } = useContext(DarkLightModeContext);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { authToken } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [inventoryList, setInventoryList] = useState([]);
  const [inventorySearch, setInventorySearch] = useState("");
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [addMore, setAddMore] = useState(false);
  const [vendorSearch, setVendorSearch] = useState("");
  const [vendorList, setVendorList] = useState([]);
  const [uomList, setUomList] = useState([]);
  const [deliveredSearch, setDeliveredSearch] = useState("");
  const [deliveredItems, setDeliveredItems] = useState([]);

  const [stagedSearch, setStagedSearch] = useState("");
  const [stagedItems, setStagedItems] = useState([]);

  const [truckSearch, setTruckSearch] = useState("");
  const [truckItems, setTruckItems] = useState([]);

  const [committedSearch, setCommittedSearch] = useState("");
  const [committedItems, setCommittedItems] = useState([]);

  const searchDeliveredItem = async (controller) => {
    try {
      setLoading(true);
      let headers = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      if (controller) {
        headers.signal = controller.signal;
      }
      const query = escapeCharacters(deliveredSearch);
      const response = await InventoryTracking.get(
        `/jobs/${jobId}/delivered-items?search=${query}`,
        headers
      );
      let data = response.data.delivered;
      for (let item of data) {
        item.label = `${item.description} [${item.material_origin_key} - qty: ${item.quantity} ${item.uom}]`;
      }

      setLoading(false);
      setDeliveredItems([...data]);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const searchStagedItem = async (controller) => {
    try {
      setLoading(true);
      let headers = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      if (controller) {
        headers.signal = controller.signal;
      }
      const query = escapeCharacters(stagedSearch);
      const response = await InventoryTracking.get(
        `/jobs/${jobId}/staged-items?search=${query}`,
        headers
      );
      let data = response.data.staged;
      for (let item of data) {
        item.label = `${item.description} [${item.material_origin_key} - qty: ${item.quantity} ${item.uom}]`;
      }

      setLoading(false);
      setStagedItems([...data]);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const searchTruckItem = async (controller) => {
    try {
      setLoading(true);
      let headers = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      if (controller) {
        headers.signal = controller.signal;
      }
      const query = escapeCharacters(truckSearch);
      const response = await InventoryTracking.get(
        `/jobs/${jobId}/loaded-on-truck-items?search=${query}`,
        headers
      );
      let data = response.data.onTruck;
      for (let item of data) {
        item.label = `${item.description} [${item.material_origin_key} - qty: ${item.quantity} ${item.uom}]`;
      }

      setLoading(false);
      setTruckItems([...data]);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const searchCommittedItem = async (controller) => {
    try {
      setLoading(true);
      let headers = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      if (controller) {
        headers.signal = controller.signal;
      }
      const query = escapeCharacters(committedSearch);
      const response = await InventoryTracking.get(
        `/jobs/${jobId}/committed-items?search=${query}`,
        headers
      );
      let data = response.data.committed;
      for (let item of data) {
        item.label = `${item.description} [${item.material_origin_key} - qty: ${item.quantity} ${item.uom}]`;
      }

      setLoading(false);
      setCommittedItems([...data]);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const searchInventoryItem = async (controller) => {
    try {
      setLoading(true);
      let headers = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      if (controller) {
        headers.signal = controller.signal;
      }
      const query = escapeCharacters(inventorySearch);
      const response = await InventoryTracking.get(
        `/inventory?search=${query}&location=SDL&pageSize=20&page=${page}`,
        headers
      );
      let data = {
        items: response.data.materials,
        pages: response.data.pages,
      };
      for (let item of data.items) {
        item.label = `[${item.internal_code}] ${item.description}`;
      }

      setLoading(false);
      setInventoryList([...data.items]);
      setPages(data.pages);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const searchVendors = async () => {
    try {
      const query = escapeCharacters(vendorSearch);
      const response = await InventoryTracking.get(
        `/vendor-search?search=${query}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      let vendors = response.data.vendors;
      for (let v of vendors) {
        v.label = v.provider;
      }
      setVendorList(vendors);
    } catch (error) {
      console.log(error);
    }
  };

  const getUomList = async () => {
    try {
      const response = await InventoryTracking.get(`/uom`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      setUomList(response.data.uomList);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    searchInventoryItem(controller);
    setPage(1);
    return () => {
      controller.abort(); // abort api call on unmount for cleanup
    };
  }, [inventorySearch]);

  useEffect(() => {
    searchVendors();
  }, [vendorSearch]);

  useEffect(() => {
    getUomList();
  }, []);

  useEffect(() => {
    if (returnFrom == "DELIVERED") searchDeliveredItem();
  }, [deliveredSearch, returnFrom]);

  useEffect(() => {
    if (returnFrom == "STAGED") searchStagedItem();
  }, [stagedSearch, returnFrom]);

  useEffect(() => {
    if (returnFrom == "TRUCK") searchTruckItem();
  }, [truckSearch, returnFrom]);

  useEffect(() => {
    if (returnFrom == "COMMITTED") searchCommittedItem();
  }, [committedSearch, returnFrom]);

  return (
    <Card
      sx={{
        marginTop: "16px",
        padding: isSmallScreen ? "16px" : "32px",
      }}
    >
      <Box>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <TextField
            disabled={!isEditable}
            sx={{ flex: 1 }}
            name="Description"
            label="Description"
            type="text"
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
          />
          <TextField
            disabled={!isEditable}
            sx={{ width: "100px", marginLeft: "8px" }}
            name="Quantity"
            label="Quantity"
            type="number"
            value={quantity}
            onChange={(e) => {
              setQuantity(e.target.value);
            }}
          />
        </div>

        <Box sx={{ marginTop: "16px" }}>
          <Typography
            id="modal-modal-title"
            variant="h7"
            sx={{
              marginTop: "16px",
              width: "100%",
              textAlign: "center",
              fontWeight: "bold",
              marginTop: "32px",
            }}
          >
            Return From
          </Typography>
        </Box>
        <Box sx={{ paddingLeft: "8px" }}>
          <Box
            sx={{
              marginTop: "16px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <FormControl fullWidth sx={{ flex: 1 }}>
              <InputLabel id="demo-simple-select-label">
                Item Returned From
              </InputLabel>
              <Select
                disabled={!isEditable}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={returnFrom}
                label="Return From"
                onChange={(e) => {
                  setReturnFrom(e.target.value);
                }}
              >
                <MenuItem value={"DELIVERED"}>Jobsite/Delivered</MenuItem>
                <MenuItem value={"STAGED"}>Staged</MenuItem>
                <MenuItem value={"TRUCK"}>Truck</MenuItem>
                <MenuItem value={"COMMITTED"}>Committed</MenuItem>
              </Select>
            </FormControl>
          </Box>

          {returnFrom == "DELIVERED" && (
            <Box sx={{ marginTop: "16px" }}>
              {returnFromId ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    padding: "12px",
                    border: 1,
                    borderColor: isDarkMode
                      ? "rgba(255, 255, 255, 0.2)"
                      : "rgba(0, 0, 0, 0.2)",
                    borderRadius: "4px",
                    paddingTop: "16px",
                    marginTop: "1px",
                  }}
                >
                  <Typography sx={{ fontWeight: "bold", marginRight: "8px" }}>
                    Item:
                  </Typography>
                  <DeliveredItem
                    sx={{
                      flex: 1,
                      //   overflow: "hidden",
                      //   textOverflow: "ellipsis",
                      //   display: "-webkit-box",
                      //   WebkitLineClamp: "1",
                      //   WebkitBoxOrient: "vertical",
                    }}
                    jobId={jobId}
                    deliveredId={returnFromId}
                  />
                  {isEditable && (
                    <Edit
                      onClick={() => {
                        setReturnFromId(null);
                      }}
                    />
                  )}
                </Box>
              ) : (
                <Box>
                  <Autocomplete
                    disabled={!isEditable}
                    fullWidth={true}
                    sx={{
                      marginRight: isSmallScreen ? 0 : "8px",
                      marginTop: isSmallScreen ? "8px" : 0,
                    }}
                    disablePortal
                    inputValue={deliveredSearch}
                    onInputChange={(event, newInputValue) => {
                      setDeliveredSearch(newInputValue);
                    }}
                    options={deliveredItems}
                    renderInput={(params) => (
                      <TextField
                        disabled={!isEditable}
                        fullWidth
                        {...params}
                        label="Search & Match Delivered Item"
                      />
                    )}
                    onChange={(event, value) => {
                      console.log("ONCHANGE", value);
                      if (value) {
                        setReturnFromId(value.id);
                        setUom(value.uom_id);
                      }
                    }}
                  />
                </Box>
              )}
            </Box>
          )}
          {returnFrom == "STAGED" && (
            <Box sx={{ marginTop: "16px" }}>
              {returnFromId ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    padding: "12px",
                    border: 1,
                    borderColor: isDarkMode
                      ? "rgba(255, 255, 255, 0.2)"
                      : "rgba(0, 0, 0, 0.2)",
                    borderRadius: "4px",
                    paddingTop: "16px",
                    marginTop: "1px",
                  }}
                >
                  <Typography sx={{ fontWeight: "bold", marginRight: "8px" }}>
                    Item:
                  </Typography>
                  <StagedItem
                    sx={{
                      flex: 1,
                      //   overflow: "hidden",
                      //   textOverflow: "ellipsis",
                      //   display: "-webkit-box",
                      //   WebkitLineClamp: "1",
                      //   WebkitBoxOrient: "vertical",
                    }}
                    jobId={jobId}
                    stagedId={returnFromId}
                  />
                  {isEditable && (
                    <Edit
                      onClick={() => {
                        setReturnFromId(null);
                      }}
                    />
                  )}
                </Box>
              ) : (
                <Box>
                  <Autocomplete
                    disabled={!isEditable}
                    fullWidth={true}
                    sx={{
                      marginRight: isSmallScreen ? 0 : "8px",
                      marginTop: isSmallScreen ? "8px" : 0,
                    }}
                    disablePortal
                    inputValue={stagedSearch}
                    onInputChange={(event, newInputValue) => {
                      setStagedSearch(newInputValue);
                    }}
                    options={stagedItems}
                    renderInput={(params) => (
                      <TextField
                        disabled={!isEditable}
                        fullWidth
                        {...params}
                        label="Search & Match Staged Item"
                      />
                    )}
                    onChange={(event, value) => {
                      console.log("ONCHANGE", value);
                      if (value) {
                        setReturnFromId(value.id);
                        setUom(value.uom_id);
                      }
                    }}
                  />
                </Box>
              )}
            </Box>
          )}
          {returnFrom == "TRUCK" && (
            <Box sx={{ marginTop: "16px" }}>
              {returnFromId ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    padding: "12px",
                    border: 1,
                    borderColor: isDarkMode
                      ? "rgba(255, 255, 255, 0.2)"
                      : "rgba(0, 0, 0, 0.2)",
                    borderRadius: "4px",
                    paddingTop: "16px",
                    marginTop: "1px",
                  }}
                >
                  <Typography sx={{ fontWeight: "bold", marginRight: "8px" }}>
                    Item:
                  </Typography>
                  <TruckItem
                    sx={{
                      flex: 1,
                      //   overflow: "hidden",
                      //   textOverflow: "ellipsis",
                      //   display: "-webkit-box",
                      //   WebkitLineClamp: "1",
                      //   WebkitBoxOrient: "vertical",
                    }}
                    jobId={jobId}
                    onTruckId={returnFromId}
                  />
                  {isEditable && (
                    <Edit
                      onClick={() => {
                        setReturnFromId(null);
                      }}
                    />
                  )}
                </Box>
              ) : (
                <Box>
                  <Autocomplete
                    disabled={!isEditable}
                    fullWidth={true}
                    sx={{
                      marginRight: isSmallScreen ? 0 : "8px",
                      marginTop: isSmallScreen ? "8px" : 0,
                    }}
                    disablePortal
                    inputValue={truckSearch}
                    onInputChange={(event, newInputValue) => {
                      setTruckSearch(newInputValue);
                    }}
                    options={truckItems}
                    renderInput={(params) => (
                      <TextField
                        disabled={!isEditable}
                        fullWidth
                        {...params}
                        label="Search & Match Loaded On Truck Item"
                      />
                    )}
                    onChange={(event, value) => {
                      console.log("ONCHANGE", value);
                      if (value) {
                        setReturnFromId(value.id);
                        setUom(value.uom_id);
                      }
                    }}
                  />
                </Box>
              )}
            </Box>
          )}
          {returnFrom == "COMMITTED" && (
            <Box sx={{ marginTop: "16px" }}>
              {returnFromId ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    padding: "12px",
                    border: 1,
                    borderColor: isDarkMode
                      ? "rgba(255, 255, 255, 0.2)"
                      : "rgba(0, 0, 0, 0.2)",
                    borderRadius: "4px",
                    paddingTop: "16px",
                    marginTop: "1px",
                  }}
                >
                  <Typography sx={{ fontWeight: "bold", marginRight: "8px" }}>
                    Item:
                  </Typography>
                  <CommittedItem
                    sx={{
                      flex: 1,
                      //   overflow: "hidden",
                      //   textOverflow: "ellipsis",
                      //   display: "-webkit-box",
                      //   WebkitLineClamp: "1",
                      //   WebkitBoxOrient: "vertical",
                    }}
                    jobId={jobId}
                    committedId={returnFromId}
                  />
                  {isEditable && (
                    <Edit
                      onClick={() => {
                        setReturnFromId(null);
                      }}
                    />
                  )}
                </Box>
              ) : (
                <Box>
                  <Autocomplete
                    disabled={!isEditable}
                    fullWidth={true}
                    sx={{
                      marginRight: isSmallScreen ? 0 : "8px",
                      marginTop: isSmallScreen ? "8px" : 0,
                    }}
                    disablePortal
                    inputValue={committedSearch}
                    onInputChange={(event, newInputValue) => {
                      setCommittedSearch(newInputValue);
                    }}
                    options={committedItems}
                    renderInput={(params) => (
                      <TextField
                        disabled={!isEditable}
                        fullWidth
                        {...params}
                        label="Search & Match Committed Item"
                      />
                    )}
                    onChange={(event, value) => {
                      console.log("ONCHANGE", value);
                      if (value) {
                        setReturnFromId(value.id);
                        setUom(value.uom_id);
                      }
                    }}
                  />
                </Box>
              )}
            </Box>
          )}
        </Box>

        <Box sx={{ marginTop: "16px" }}>
          <Typography
            id="modal-modal-title"
            variant="h7"
            sx={{
              marginTop: "16px",
              width: "100%",
              textAlign: "center",
              fontWeight: "bold",
              marginTop: "32px",
            }}
          >
            Return To
          </Typography>
        </Box>
        <Box sx={{ paddingLeft: "8px" }}>
          <Box
            sx={{
              marginTop: "16px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <FormControl fullWidth sx={{ flex: 1 }}>
              <InputLabel id="demo-simple-select-label">
                Return Destination
              </InputLabel>
              <Select
                disabled={!isEditable}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={returnTo}
                label="Return To"
                onChange={(e) => {
                  setReturnTo(e.target.value);
                }}
              >
                <MenuItem value={"INVENTORY"}>Inventory</MenuItem>
                <MenuItem value={"VENDOR"}>Vendor</MenuItem>
                <MenuItem value={"BONEYARD"}>Boneyard</MenuItem>
              </Select>
            </FormControl>
          </Box>

          {returnTo == "INVENTORY" && (
            <Box sx={{ marginTop: "16px" }}>
              {inventoryItemId ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    padding: "12px",
                    border: 1,
                    borderColor: isDarkMode
                      ? "rgba(255, 255, 255, 0.2)"
                      : "rgba(0, 0, 0, 0.2)",
                    borderRadius: "4px",
                    paddingTop: "16px",
                    marginTop: "1px",
                  }}
                >
                  <Typography sx={{ fontWeight: "bold", marginRight: "8px" }}>
                    Item:
                  </Typography>
                  <InventoryItem
                    sx={{
                      flex: 1,
                      //   overflow: "hidden",
                      //   textOverflow: "ellipsis",
                      //   display: "-webkit-box",
                      //   WebkitLineClamp: "1",
                      //   WebkitBoxOrient: "vertical",
                    }}
                    itemId={inventoryItemId}
                  />
                  {isEditable && (
                    <Edit
                      onClick={() => {
                        setInventoryItemId(null);
                      }}
                    />
                  )}
                </Box>
              ) : (
                <Box>
                  <Autocomplete
                    disabled={!isEditable}
                    fullWidth={true}
                    sx={{
                      marginRight: isSmallScreen ? 0 : "8px",
                      marginTop: isSmallScreen ? "8px" : 0,
                    }}
                    disablePortal
                    inputValue={inventorySearch}
                    onInputChange={(event, newInputValue) => {
                      setInventorySearch(newInputValue);
                    }}
                    options={inventoryList}
                    renderInput={(params) => (
                      <TextField
                        disabled={!isEditable}
                        fullWidth
                        {...params}
                        label="Search & Select Inventory Item"
                      />
                    )}
                    onChange={(event, value) => {
                      console.log("ONCHANGE", value);
                      if (value) setInventoryItemId(value.itemId);
                    }}
                  />
                </Box>
              )}
            </Box>
          )}
          {returnTo == "VENDOR" && (
            <Box
              sx={{
                marginTop: "16px",
              }}
            >
              <Box>
                {vendorId ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      padding: "12px",
                      border: 1,
                      borderColor: isDarkMode
                        ? "rgba(255, 255, 255, 0.2)"
                        : "rgba(0, 0, 0, 0.2)",
                      borderRadius: "4px",
                      paddingTop: "16px",
                      marginTop: "1px",
                    }}
                  >
                    <Typography
                      fullWidth
                      sx={{ fontWeight: "bold", marginRight: "8px" }}
                    >
                      Vendor:
                    </Typography>
                    <Vendor
                      sx={{
                        flex: 1,
                        // overflow: "hidden",
                        // textOverflow: "ellipsis",
                        // display: "-webkit-box",
                        // WebkitLineClamp: "1",
                        // WebkitBoxOrient: "vertical",
                      }}
                      id={vendorId}
                    />
                    {isEditable && (
                      <Edit
                        onClick={() => {
                          setVendorId(null);
                        }}
                      />
                    )}
                  </Box>
                ) : (
                  <Box>
                    <Autocomplete
                      disabled={!isEditable}
                      fullWidth={true}
                      sx={{
                        marginRight: isSmallScreen ? 0 : "8px",
                        marginTop: isSmallScreen ? "8px" : 0,
                      }}
                      disablePortal
                      inputValue={vendorSearch}
                      onInputChange={(event, newInputValue) => {
                        setVendorSearch(newInputValue);
                      }}
                      options={vendorList}
                      renderInput={(params) => (
                        <TextField
                          disabled={!isEditable}
                          fullWidth
                          {...params}
                          label="Search & Select Vendor"
                        />
                      )}
                      onChange={(event, value) => {
                        console.log("ONCHANGE", value);
                        if (value) setVendorId(value.id);
                      }}
                    />
                  </Box>
                )}
              </Box>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                {uomList && uomList.length > 0 && (
                  <FormControl
                    disabled={!isEditable}
                    sx={{
                      flex: 1,
                      marginTop: "16px",
                    }}
                  >
                    <InputLabel id="demo-simple-select-label">UOM</InputLabel>
                    <Select
                      disabled={!isEditable}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={uom}
                      label="UOM"
                      onChange={(e) => {
                        setUom(e.target.value);
                      }}
                    >
                      {uomList.map((uomItem, index) => {
                        return (
                          <MenuItem value={uomItem.id}>
                            {uomItem.description}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                )}

                <TextField
                  disabled={!isEditable}
                  sx={{ flex: 1, marginTop: "16px", marginLeft: "8px" }}
                  name="Price"
                  label="Price"
                  type="number"
                  value={price}
                  onChange={(e) => {
                    setPrice(e.target.value);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Box>
          )}
          {returnTo == "BONEYARD" && (
            <Box
              sx={{
                marginTop: "16px",
                display: "flex",
                flexDirection: isSmallScreen ? "column" : "row",
              }}
            >
              {uomList && uomList.length > 0 && (
                <FormControl
                  sx={{
                    width: "50%",
                  }}
                >
                  <InputLabel id="demo-simple-select-label">UOM</InputLabel>
                  <Select
                    disabled={!isEditable}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={uom}
                    label="UOM"
                    onChange={(e) => {
                      setUom(e.target.value);
                    }}
                  >
                    {uomList.map((uomItem, index) => {
                      return (
                        <MenuItem value={uomItem.id}>
                          {uomItem.description}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              )}
            </Box>
          )}
        </Box>
        {isEditable && (
          <Box
            sx={{
              marginTop: "16px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="text"
              onClick={() => {
                onRemove();
              }}
            >
              Remove
            </Button>
          </Box>
        )}
      </Box>
    </Card>
  );
};
export default ReturnItemDetailsCard;
