import { useContext, useEffect, useState } from "react";
import InventoryTracking from "../../../../api/InventoryTracking";
import { AuthContext } from "../../../../context/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import PageContent from "../../../../component/PageContent";
import {
  Box,
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import CustomQrCodeScanner from "../../../../component/CustomQrCodeScanner";
import toFixedIfNecessary from "../../../../helpers/toFixedIfNecessary";
import { useTheme } from "@emotion/react";
import { SnackAlertContext } from "../../../../context/SnackAlertContext";
import { LoadingButton } from "@mui/lab";
import DebouncedTextField from "../../../../component/DebouncedTextField";

const defaultData = {
  branch: "SDL",
  count: {},
};

const InventoryCount = () => {
  const { authToken, isMaterialAppAdmin } = useContext(AuthContext);
  const { id } = useParams();
  const [items, setItems] = useState();
  const [inventoryCount, setInventoryCount] = useState(null);
  const navigate = useNavigate();
  const [qrScan, setQrScan] = useState("");
  const [scannedItem, setScannedItem] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { openSnackMessage } = useContext(SnackAlertContext);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [loading, setLoading] = useState(false);

  const [isAllChecked, setIsAllChecked] = useState(false);

  useEffect(() => {
    if (items && inventoryCount && inventoryCount.count)
      setIsAllChecked(Object.keys(inventoryCount.count).length == items.length);
  }, [inventoryCount, items]);

  const AndreasDesiredOrderOfThings = [
    // subject to change??? hope not
    "SGP000053",
    "CTG000027",
    "CTG000034",
    "CTG000042",
    "TIL0000001",
    "MSC000016",
    "UND000013",
    "SGP000058",
    "SGP000023",
    "MSC000009",
    "MSC00010",
    "MSC000011",
    "MSC000006",
    "MSC000007",
    "ADH000003",
    "CTG000036",
    "ADH000004",
    "CTG000013",
    "CTG000016",
    "CTG000015",
    "SGP000035",
    "TIL0000005",
    "TIL0000007",
    "TIL0000003",
    "TIL0000046",
    "TIL0000008",
    "TIL0000002",
    "OTH000001",
    "PJS000011",
    "PJS000001",
    "PJS000002",
    "PJS000004",
    "PJS000023",
    "PJS000012",
    "PJS000013",
    "OTH000013",
    "PJS000010",
    "PJS000016",
    "SHG000017",
    "TIL0000034",
    "TIL0000031",
    "SHG000022",
    "SHG000054",
    "OTH000011",
    "OTH000010",
    "OTH000004",
    "FLS000048",
    "FLS000004",
    "FLS0000007",
    "FLS00000036",
    "FLS0000002",
    "FLS0000013",
    "FLS0000014",
    "FLS0000015",
    "FLS000017",
    "FLS000041",
    "FLS000003",
    "FLS0000011",
    "FLS00000040",
    "FLS000044",
    "FLS000020",
    "FLS000001",
    "FLS000045",
    "FLS000002",
    "FLS000021",
    "FLS0000009",
    "FLS0000032",
    "FLS000025",
    "TIL000013",
    "SPF000001",
    "SPF0000002",
    "SGP000038",
    "SHG000023",
    "FST000012",
    "FST000007",
    "FST000010",
    "SGP000059",
    "SGP000056",
    "FST000011",
    "FST000022",
    "FST000017",
    "FST000018",
    "FST000025",
    "FST000008",
    "SGP000007",
    "FST000004",
    "FST000037",
    "FST000015",
    "FST000019",
    "SGP000036",
    "SLT000009",
    "SLT000010",
    "SLT000013",
    "OTH000008",
    "OTH000017",
    "OTH000018",
    "OTH000019",
    "SLT000007",
    "CTG000003",
    "OTH000014",
    "OTH000015",
    "OTH000043",
    "SGP000006",
    "SGP000062",
    "SGP000014",
    "BUR000008",
    "SGP000061",
    "FST000035",
    "FST000055",
    "FST000032",
  ];
  const sortItems = (list, targetOrder = AndreasDesiredOrderOfThings) => {
    const sorted = targetOrder.reduce(
      (acc, curr) => [
        ...acc,
        ...list.filter((item) => item.internal_code === curr),
      ],
      []
    );
    const unmatched = list.filter(
      (item) => !targetOrder.includes(item.internal_code)
    );
    return [...sorted, ...unmatched];
  };

  const getAll = async () => {
    try {
      const res = await InventoryTracking.get(
        // `/inventory/all?branch=${branch}`,
        `/inventory/all?branch=SDL`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (res.data.inventory) {
        const sortedItems = sortItems(res.data.inventory);

        setItems(sortedItems);
      }
      // setItems(res.data.inventory);
    } catch (error) {
      console.log(error);
    }
  };

  const getInventoryCountDetails = async () => {
    try {
      const response = await InventoryTracking.get(`/inventory/counts/${id}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      console.log(response.data);
      setInventoryCount(response.data.inventoryCount);
    } catch (error) {
      console.log(error);
    }
  };

  const save = async () => {
    try {
      console.log("SAVE");
      setIsLoading(true);
      const response = await InventoryTracking.post(
        `/inventory/counts`,
        inventoryCount,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      openSnackMessage("success", "Saved");
      navigate("/inventory/counts");
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const update = async () => {
    try {
      setIsLoading(true);
      const response = await InventoryTracking.put(
        `/inventory/counts/${id}`,
        inventoryCount,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      openSnackMessage("success", "Updated");
      getAll();
      getInventoryCountDetails(id);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const submit = async () => {
    try {
      setIsLoading(true);
      const response = await InventoryTracking.put(
        `/inventory/counts/${id}/submit`,
        inventoryCount,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      openSnackMessage("success", "Submitted for Review");
      getAll();
      getInventoryCountDetails(id);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const approve = async () => {
    try {
      setIsLoading(true);
      const response = await InventoryTracking.put(
        `/inventory/counts/${id}/approve`,
        inventoryCount,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      openSnackMessage("success", "Approved!");
      getAll();
      getInventoryCountDetails(id);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getItemDetailFromScan = async (scannedUrl) => {
    try {
      setLoading(true);

      if (scannedUrl.includes("/jobs/")) {
        let jobId = scannedUrl.split("/jobs/")[1];
      } else {
        // Parse Item QR Code URL
        let itemUri;
        let itemId;
        if (scannedUrl.includes("/inventory/")) {
          itemUri = "inventory";
          itemId = scannedUrl.split("/inventory/")[1];

          console.log(`getting item /${itemUri}/${itemId}`);
          // Get Item Details
          const response = await InventoryTracking.get(
            `/${itemUri}/${itemId}`,
            {
              headers: {
                Authorization: `Bearer ${authToken}`,
              },
            }
          );

          let material = response.data.material;
          console.log(material);

          // Set Modal Data
          if (scannedUrl.includes("/inventory/")) {
            setScannedItem({
              source: itemUri,
              details: material,
              name: material.description,
              image: material.image_url,
              uom: material.uom,
              quantity: material.quantity,
              itemId: material.itemId,
            });
          }
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAll();
  }, []);

  useEffect(() => {
    if (id) {
      getInventoryCountDetails(id);
    } else {
      setInventoryCount(defaultData);
    }
  }, [id]);

  useEffect(() => {
    console.log("INVENTORY COUNT", inventoryCount);
  }, [inventoryCount]);

  useEffect(() => {
    console.log("qrScan", qrScan);
    if (qrScan) {
      // Get Item Details from qr scan link
      getItemDetailFromScan(qrScan);
    }
  }, [qrScan]);

  return (
    <PageContent>
      <h2
        style={{
          textAlign: "center",
        }}
      >
        Inventory Count
      </h2>
      <CustomQrCodeScanner
        buttonStyle={{ position: "fixed", bottom: "50px", right: "20px" }}
        onResult={(result) => {
          console.log("SCAN RESULT", result);
          setQrScan(result);
        }}
      />
      <Modal
        open={scannedItem !== null}
        onClose={() => {
          setScannedItem(null);
          setQrScan("");
        }}
      >
        <Card
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            minWidth: 300,
            maxWidth: 500,
            maxHeight: "100%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            display: "block",
            overflow: "scroll",
            overflowX: "hidden",
          }}
        >
          {scannedItem ? (
            <div style={{ flex: 1 }}>
              <h3>Pull from {scannedItem.source}</h3>
              {!isSmallScreen && (
                <div
                  style={{
                    borderRadius: 0,
                    marginTop: 1,
                    display: "flex",
                    flexDirection: "row",
                    padding: 8,
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      width: 100,
                      paddingLeft: 12,
                      paddingRight: 12,
                      fontSize: 12,
                    }}
                  >
                    <p
                      style={{
                        margin: 0,
                        fontWeight: "bold",
                        //textAlign: "center",
                      }}
                    >
                      Image
                    </p>
                  </div>
                  <div style={{ flex: 1, fontSize: 12 }}>
                    <p
                      style={{
                        margin: 0,
                        fontWeight: "bold",
                        //textAlign: "center",
                      }}
                    >
                      Description
                    </p>
                  </div>
                  <div style={{ minWidth: 80, fontSize: 12 }}>
                    <p
                      style={{
                        margin: 0,
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      UOM
                    </p>
                  </div>
                  <div style={{ minWidth: 100, fontSize: 12 }}>
                    <p
                      style={{
                        margin: 0,
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      Quantity
                    </p>
                  </div>
                </div>
              )}
              {isSmallScreen ? (
                <div style={{ padding: 8, marginTop: 4 }}>
                  <div
                    style={{
                      flex: 1,
                      fontSize: 12,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "relative",
                    }}
                  >
                    <div style={{ width: 100, padding: 12 }}>
                      <img
                        src={scannedItem.image}
                        style={{ width: "100%", aspectRatio: 15 / 20 }}
                      />
                    </div>
                    <p style={{ flex: 1, margin: 0 }}>{scannedItem.name}</p>
                  </div>

                  <div
                    style={{
                      borderRadius: 0,
                      marginTop: 8,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                        fontSize: 12,
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                      }}
                    >
                      <p style={{ margin: 0, padding: 8, textAlign: "end" }}>
                        {scannedItem.uom}
                      </p>
                    </div>
                    <div
                      style={{
                        flex: 1,
                      }}
                    >
                      <TextField
                        label="Quantity"
                        type="number"
                        value={scannedItem.quantity}
                        onChange={(e) => {
                          let val;
                          if (
                            e.target.value == "" ||
                            e.target.value === undefined ||
                            e.target.value === null ||
                            e.target.value === "0" ||
                            e.target.value === "00"
                          ) {
                            val = 0;
                          } else {
                            val = e.target.value.replace(/^0+/, "");
                            val = toFixedIfNecessary(val, 2);
                          }
                          // Do Not Allow Negative
                          if (val < 0) {
                            val = 0;
                          }
                          setScannedItem((prev) => ({
                            ...prev,
                            quantity: val,
                          }));
                        }}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    borderRadius: 0,
                    marginTop: 1,
                    display: "flex",
                    flexDirection: "row",
                    padding: 8,
                    position: "relative",
                    alignItems: "center",
                  }}
                >
                  <div style={{ width: 100, padding: 12 }}>
                    <img
                      src={scannedItem.image}
                      style={{ width: "100%", aspectRatio: 15 / 20 }}
                    />
                  </div>
                  <div
                    style={{
                      flex: 1,
                      fontSize: 12,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <p style={{ flex: 1, margin: 0 }}>{scannedItem.name}</p>
                  </div>
                  <div
                    style={{
                      width: 100,
                      fontSize: 12,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <p style={{ margin: 0, textAlign: "center" }}>
                      {scannedItem.uom}
                    </p>
                  </div>
                  <TextField
                    style={{
                      width: 80,
                    }}
                    label="Quantity"
                    type="number"
                    value={scannedItem.quantity}
                    onChange={(e) => {
                      let val;
                      if (
                        e.target.value == "" ||
                        e.target.value === undefined ||
                        e.target.value === null ||
                        e.target.value === "0" ||
                        e.target.value === "00"
                      ) {
                        val = 0;
                      } else {
                        val = e.target.value.replace(/^0+/, "");
                        val = toFixedIfNecessary(val, 2);
                      }
                      // Do Not Allow Negative
                      if (val < 0) {
                        val = 0;
                      }
                      setScannedItem((prev) => ({ ...prev, quantity: val }));
                    }}
                  />
                </div>
              )}

              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  style={{ width: 100, marginRight: 8 }}
                  variant="outlined"
                  onClick={() => {
                    setScannedItem(null);
                    setQrScan("");
                  }}
                >
                  Cancel
                </Button>
                <Button
                  style={{ width: 100 }}
                  variant="contained"
                  onClick={() => {
                    let countCopy = { ...inventoryCount.count };
                    countCopy[scannedItem.itemId] = scannedItem.quantity;
                    setInventoryCount((prev) => ({
                      ...prev,
                      count: countCopy,
                    }));

                    setScannedItem(null);
                    setQrScan("");
                  }}
                >
                  Ok
                </Button>
              </div>
            </div>
          ) : null}
        </Card>
      </Modal>
      <TableContainer component={Box}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <FormControlLabel
                  label="All"
                  control={
                    <Checkbox
                      disabled={inventoryCount && inventoryCount.approved_on}
                      checked={isAllChecked}
                      onChange={(e) => {
                        if (e.target.checked) {
                          let countCopy = { ...inventoryCount.count };

                          for (const item of items) {
                            if (!countCopy.hasOwnProperty(item.itemId)) {
                              countCopy[item.itemId] = 0;
                            }
                          }

                          setInventoryCount((prev) => ({
                            ...prev,
                            count: countCopy,
                          }));
                        }
                      }}
                    />
                  }
                />
              </TableCell>
              <TableCell>ID</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>UOM</TableCell>
              {inventoryCount && !inventoryCount.approved_on && (
                <TableCell>Current Qty</TableCell>
              )}

              <TableCell>Count</TableCell>
              {inventoryCount && !inventoryCount.approved_on && (
                <TableCell>Change</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {inventoryCount &&
              items &&
              items.map((item, index) => {
                if (!inventoryCount.approved_on) {
                  return (
                    <TableRow
                      key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell>
                        <Checkbox
                          disabled={
                            inventoryCount && inventoryCount.approved_on
                          }
                          checked={
                            inventoryCount.count[item.itemId] != null &&
                            inventoryCount.count[item.itemId] != undefined
                          }
                          onChange={(e) => {
                            let countCopy = { ...inventoryCount.count };

                            if (e.target.checked) {
                              countCopy[item.itemId] = 0;
                            } else {
                              console.log("DELETE", item.itemId);
                              delete countCopy[item.itemId];
                            }
                            console.log("UPDATE", countCopy);
                            setInventoryCount((prev) => ({
                              ...prev,
                              count: countCopy,
                            }));
                          }}
                        />
                      </TableCell>
                      <TableCell>{item.internal_code}</TableCell>
                      <TableCell>{item.description}</TableCell>
                      <TableCell>{item.uom}</TableCell>
                      <TableCell>{item.quantity}</TableCell>
                      <TableCell>
                        <DebouncedTextField
                          delay={250}
                          sx={{ width: "80px" }}
                          name="Count"
                          label="Count"
                          type="number"
                          disabled={
                            !inventoryCount.count.hasOwnProperty(item.itemId) ||
                            (inventoryCount && inventoryCount.approved_on)
                          }
                          value={
                            inventoryCount.count.hasOwnProperty(item.itemId)
                              ? inventoryCount.count[item.itemId]
                              : 0
                          }
                          onChange={(e) => {
                            if (
                              inventoryCount.count.hasOwnProperty(item.itemId)
                            ) {
                              console.log("ON CHANGE");

                              let countCopy = { ...inventoryCount.count };
                              countCopy[item.itemId] = parseInt(e.target.value);
                              setInventoryCount((prev) => ({
                                ...prev,
                                count: countCopy,
                              }));
                            }
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        {isNaN(
                          inventoryCount.count[item.itemId] - item.quantity
                        ) ? (
                          ""
                        ) : inventoryCount.count[item.itemId] - item.quantity >=
                          0 ? (
                          <Typography color="lightgreen">
                            +{inventoryCount.count[item.itemId] - item.quantity}
                          </Typography>
                        ) : (
                          <Typography color="red">
                            {inventoryCount.count[item.itemId] - item.quantity}
                          </Typography>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                } else if (inventoryCount.count.hasOwnProperty(item.itemId))
                  return (
                    <TableRow
                      key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell>
                        <Checkbox
                          disabled={
                            inventoryCount && inventoryCount.approved_on
                          }
                          checked={
                            inventoryCount.count[item.itemId] != null &&
                            inventoryCount.count[item.itemId] != undefined
                          }
                          onChange={(e) => {
                            let countCopy = { ...inventoryCount.count };

                            if (e.target.checked) {
                              countCopy[item.itemId] = 0;
                            } else {
                              console.log("DELETE", item.itemId);
                              delete countCopy[item.itemId];
                            }
                            console.log("UPDATE", countCopy);
                            setInventoryCount((prev) => ({
                              ...prev,
                              count: countCopy,
                            }));
                          }}
                        />
                      </TableCell>
                      <TableCell>{item.internal_code}</TableCell>
                      <TableCell>{item.description}</TableCell>
                      <TableCell>{item.uom}</TableCell>
                      <TableCell>
                        <DebouncedTextField
                          delay={250}
                          sx={{ width: "80px" }}
                          name="Count"
                          label="Count"
                          type="number"
                          disabled={
                            !inventoryCount.count.hasOwnProperty(item.itemId) ||
                            (inventoryCount && inventoryCount.approved_on)
                          }
                          value={
                            inventoryCount.count.hasOwnProperty(item.itemId)
                              ? inventoryCount.count[item.itemId]
                              : 0
                          }
                          onChange={(e) => {
                            if (
                              inventoryCount.count.hasOwnProperty(item.itemId)
                            ) {
                              console.log("ON CHANGE");

                              let countCopy = { ...inventoryCount.count };
                              countCopy[item.itemId] = parseInt(e.target.value);
                              setInventoryCount((prev) => ({
                                ...prev,
                                count: countCopy,
                              }));
                            }
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      {inventoryCount &&
        !inventoryCount.approved_on &&
        !inventoryCount.approved_by && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <LoadingButton
              loading={isLoading}
              variant="outlined"
              onClick={() => {
                if (id && id > 0 && inventoryCount.id) update();
                else save();
              }}
            >
              Save
            </LoadingButton>
            {inventoryCount &&
              inventoryCount.id &&
              !inventoryCount.approved_on && (
                <LoadingButton
                  loading={isLoading}
                  variant="outlined"
                  onClick={() => {
                    submit();
                  }}
                  sx={{ marginLeft: "8px" }}
                >
                  Submit
                </LoadingButton>
              )}
            {isMaterialAppAdmin() &&
              inventoryCount &&
              inventoryCount.id &&
              !inventoryCount.approved_on && (
                <LoadingButton
                  loading={isLoading}
                  color="success"
                  variant="contained"
                  onClick={approve}
                  sx={{ marginLeft: "8px" }}
                >
                  Approve
                </LoadingButton>
              )}
          </Box>
        )}
    </PageContent>
  );
};

export default InventoryCount;
