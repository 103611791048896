import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Dialog,
  DialogActions,
  Modal,
  TextareaAutosize,
  Typography,
  useMediaQuery,
  Checkbox,
  IconButton,
  Box,
  TextField,
  Grid,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditPoItem from "./EditPoItem";
import { AuthContext } from "../context/AuthContext";
import { SnackAlertContext } from "../context/SnackAlertContext";
import InventoryTracking from "../api/InventoryTracking";
import {
  AddAPhoto,
  Camera,
  Close,
  FileUpload,
  Photo,
  Upload,
} from "@mui/icons-material";
import imageCompression from "browser-image-compression";
import EditNonMaterialPoItem from "./EditNonMaterialPoItem";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { useTheme } from "@emotion/react";
import { DarkLightModeContext } from "../context/DarkLightModeContext";
import momentPhoenix from "../helpers/momentPhoenix";

const PendingPoModal = ({
  pendingPoModalData,
  setPendingPoModalData,
  // saveItemQuantityReceived,
  // receivePendingPo,
  // isLoading,
  onUpdatePo,
  //onPostPo,
}) => {
  const { isDarkMode } = useContext(DarkLightModeContext);
  const theme = useTheme();
  const navigate = useNavigate();
  const [shareOpen, setShareOpen] = useState(false);
  const { authToken, role } = useContext(AuthContext);
  const { openSnackMessage } = useContext(SnackAlertContext);

  const [isLoading, setIsLoading] = useState(false);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [checkedAll, setCheckedAll] = useState(false);
  const [enableReceive, setEnableReceive] = useState(false);
  const [enablePost, setEnablePost] = useState(false);

  const uploadRef = useRef(null);
  const cameraRef = useRef(null);

  const handleReceiveAllCheckChange = (isChecked) => {
    let itemsCopy = [...pendingPoModalData.items];
    if (isChecked) {
      for (let item of itemsCopy) {
        if (item.is_not_material != 1) {
          item.qty_received = item.quantity;
        }
      }
    } else {
      for (let item of itemsCopy) {
        if (item.is_not_material != 1) {
          item.qty_received = 0;
        }
      }
    }
    setPendingPoModalData((prev) => ({ ...prev, items: itemsCopy }));
    setCheckedAll(isChecked);
  };

  const handleItemOrderedQuantityChange = (orderedQuantity, index) => {
    let poItems = [...pendingPoModalData.items];
    poItems[index] = {
      ...poItems[index],
      quantity: orderedQuantity,
    };
    setPendingPoModalData((prev) => ({
      ...prev,
      items: poItems,
    }));
  };

  const handleItemReceivedQuantityChange = (receivedQuantity, index) => {
    let poItems = [...pendingPoModalData.items];
    poItems[index] = {
      ...poItems[index],
      qty_received: receivedQuantity,
    };
    setPendingPoModalData((prev) => ({
      ...prev,
      items: poItems,
    }));
  };

  const handleItemPriceChange = (price, index) => {
    let poItems = [...pendingPoModalData.items];
    poItems[index] = {
      ...poItems[index],
      price: price,
    };
    setPendingPoModalData((prev) => ({
      ...prev,
      items: poItems,
    }));
  };

  const receivedAtLeastAll = () => {
    let shouldCheckAll = true;
    for (let item of pendingPoModalData.items) {
      if (item.is_not_material != 1) {
        if (
          item.qty_received === null ||
          item.qty_received === undefined ||
          item.qty_received < item.quantity
        ) {
          shouldCheckAll = false;
        }
      }
    }
    setCheckedAll(shouldCheckAll);
  };

  const checkIfReceivable = () => {
    if (pendingPoModalData.is_received) setEnableReceive(false);
    else {
      let receivable = true;
      for (let item of pendingPoModalData.items) {
        if (item.is_not_material != 1 && !item.is_deleted) {
          if (
            item.qty_received === null ||
            item.qty_received === undefined ||
            item.qty_received != item.quantity
          ) {
            receivable = false;
          }
        }
      }
      console.log("Enable Receive?", receivable);
      setEnableReceive(receivable);
    }
  };

  const checkIfPostable = () => {
    // If Already Posted, disable
    if (pendingPoModalData.status == 9) {
      // Status == Posted
      setEnablePost(false);
    } else {
      // If Not Posted and is received, enable
      if (pendingPoModalData.is_received) setEnablePost(true);
      else {
        // If Not Posted and is NOT received, disable
        setEnablePost(false);
      }
    }
  };

  // Uploads an Array Of Images
  // Returns an Array of Entity Image object with id, entity_type, image_url
  const uploadImages = async () => {
    let imageFormDataArray = [];

    let x = Math.ceil(pendingPoModalData.imageBlobs.length / 15);
    console.log("x = ", x);
    for (let i = 0; i < x; i++) {
      let imgFormData = new FormData();
      for (
        let j = 15 * i;
        j < 15 * (i + 1) && j < pendingPoModalData.imageBlobs.length;
        j++
      ) {
        imgFormData.append("images", pendingPoModalData.imageBlobs[j]);
      }
      console.log(imgFormData);
      imageFormDataArray.push(imgFormData);
    }
    console.log("imageFormDataArray", imageFormDataArray);
    let imageUrls = [];
    for (let data of imageFormDataArray) {
      data.append("resourceType", "public/received");
      let response = await InventoryTracking.post(`/uploads/images`, data, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "content-type": "multipart/form-data",
        },
      });
      imageUrls = [...imageUrls, ...response.data.files];
    }
    console.log("imageUrls uploaded", imageUrls);
    let images = [];
    for (let img of imageUrls) {
      images.push({
        image_url: img.url,
      });
    }
    return images;
  };

  console.log("pendingPoModalData", pendingPoModalData);

  const updatePo = async () => {
    try {
      setIsLoading(true);
      let savedImageCount = pendingPoModalData.images
        ? pendingPoModalData.images.length
        : 0;
      let unsavedImageCount = pendingPoModalData.imageBlobs
        ? pendingPoModalData.imageBlobs.length
        : 0;
      let allImageCount = savedImageCount + unsavedImageCount;
      if (allImageCount === 0) {
        throw "Image is required";
      } else if (allImageCount > 30) {
        throw "You can only upload up to 30 images";
      } else {
        const newImages = await uploadImages();

        let updatedItems = [];
        let deletedItems = [];
        for (const item of pendingPoModalData.items) {
          if (item.is_deleted) {
            deletedItems.push(item);
          } else {
            updatedItems.push(item);
          }
        }

        await InventoryTracking.put(
          `/po/${pendingPoModalData.id}`,
          {
            receive_notes: pendingPoModalData.receive_notes,
            images: [...pendingPoModalData.images, ...newImages],
            updatedItems,
            deletedItems,
          },
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        openSnackMessage("success", "PO Updated!");
        setPendingPoModalData(null);
        navigate("/");
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.error);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", error);
      }
    } finally {
      setIsLoading(false);
      onUpdatePo();
    }
  };

  const updateAndReceivePo = async () => {
    try {
      setIsLoading(true);
      let savedImageCount = pendingPoModalData.images
        ? pendingPoModalData.images.length
        : 0;
      let unsavedImageCount = pendingPoModalData.imageBlobs
        ? pendingPoModalData.imageBlobs.length
        : 0;
      let allImageCount = savedImageCount + unsavedImageCount;
      if (allImageCount === 0) {
        throw "Image is required";
      } else if (allImageCount > 30) {
        throw "You can only upload up to 30 images";
      } else {
        const newImages = await uploadImages();

        let updatedItems = [];
        let deletedItems = [];
        for (const item of pendingPoModalData.items) {
          if (item.is_deleted) {
            deletedItems.push(item);
          } else {
            updatedItems.push(item);
          }
        }

        await InventoryTracking.put(
          `/po/${pendingPoModalData.id}/receive`,
          {
            receive_notes: pendingPoModalData.receive_notes,
            images: [...pendingPoModalData.images, ...newImages],
            updatedItems,
            deletedItems,
          },
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        openSnackMessage("success", "PO Received");
        setPendingPoModalData(null);
        navigate("/");
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.error);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", error);
      }
    } finally {
      setIsLoading(false);
      onUpdatePo();
    }
  };

  const updateAndUnlockReceivedPo = async () => {
    try {
      setIsLoading(true);
      let savedImageCount = pendingPoModalData.images
        ? pendingPoModalData.images.length
        : 0;
      let unsavedImageCount = pendingPoModalData.imageBlobs
        ? pendingPoModalData.imageBlobs.length
        : 0;
      let allImageCount = savedImageCount + unsavedImageCount;
      if (allImageCount === 0) {
        throw "Image is required";
      } else if (allImageCount > 30) {
        throw "You can only upload up to 30 images";
      } else {
        const newImages = await uploadImages();

        let updatedItems = [];
        let deletedItems = [];
        for (const item of pendingPoModalData.items) {
          if (item.is_deleted) {
            deletedItems.push(item);
          } else {
            updatedItems.push(item);
          }
        }

        await InventoryTracking.put(
          `/po/${pendingPoModalData.id}/unlock`,
          {
            receive_notes: pendingPoModalData.receive_notes,
            images: [...pendingPoModalData.images, ...newImages],
            updatedItems,
            deletedItems,
          },
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        openSnackMessage("success", "PO Unlocked");
        setPendingPoModalData(null);
        navigate("/");
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.error);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", error);
      }
    } finally {
      setIsLoading(false);
      onUpdatePo();
    }
  };

  // const updateAndPostPo = async () => {
  //   try {
  //     setIsLoading(true);
  //     let savedImageCount = pendingPoModalData.images
  //       ? pendingPoModalData.images.length
  //       : 0;
  //     let unsavedImageCount = pendingPoModalData.imageBlobs
  //       ? pendingPoModalData.imageBlobs.length
  //       : 0;
  //     let allImageCount = savedImageCount + unsavedImageCount;
  //     if (allImageCount === 0) {
  //       throw "Image is required";
  //     } else {
  //       const newImages = await uploadImages();

  //       let updatedItems = [];
  //       let deletedItems = [];
  //       for (const item of pendingPoModalData.items) {
  //         if (item.is_deleted) {
  //           deletedItems.push(item);
  //         } else {
  //           updatedItems.push(item);
  //         }
  //       }

  //       await InventoryTracking.put(
  //         `/po/${pendingPoModalData.id}/post`,
  //         {
  //           receive_notes: pendingPoModalData.receive_notes,
  //           images: [...pendingPoModalData.images, ...newImages],
  //           updatedItems,
  //           deletedItems,
  //         },
  //         {
  //           headers: {
  //             Authorization: `Bearer ${authToken}`,
  //           },
  //         }
  //       );
  //       openSnackMessage("success", "PO Posted!");
  //       setPendingPoModalData(null);
  //       navigate("/");
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     if (error.response) {
  //       // The request was made and the server responded with a status code
  //       // that falls out of the range of 2xx
  //       console.log(error.response.data);
  //       console.log(error.response.status);
  //       console.log(error.response.headers);
  //       openSnackMessage("error", error.response.data.error);
  //     } else {
  //       // Something went really bad
  //       console.log(error);
  //       openSnackMessage("error", error);
  //     }
  //   } finally {
  //     setIsLoading(false);
  //     onUpdatePo();
  //   }
  // };

  useEffect(() => {
    if (pendingPoModalData && pendingPoModalData.items) {
      receivedAtLeastAll();
      checkIfReceivable();
      checkIfPostable();
    }
  }, [pendingPoModalData]);

  let isUserAdmin = role.toLowerCase() === "admin";
  let isPoEditable = // Allow Edit IF
    // - Not A Stockout PO (always)
    // - Status = Pending
    // - Or Status = Open AND is an Inventory PO
    pendingPoModalData &&
    // !pendingPoModalData.is_received &&
    pendingPoModalData.po_type !== "STOCKOUT" &&
    // PENDING (For Job POs only)
    (pendingPoModalData.status === 11 || // OR
      // INVENTORY PO & OPEN
      (pendingPoModalData.status === 8 &&
        pendingPoModalData.poid.includes("INV-")));

  let allowDelete = !(!isUserAdmin || !isPoEditable);

  let displayStatus = "";
  if (pendingPoModalData) {
    if (pendingPoModalData.poid.includes("INV-")) {
      if (pendingPoModalData.status === 8) {
        if (pendingPoModalData.is_partially_received == 1) {
          displayStatus = `Status: PARTIALLY RECEIVED`;
        } else if (pendingPoModalData.is_partially_received == 0) {
          displayStatus = `Status: READY TO POST`;
        } else {
          displayStatus = `Status: NOT YET RECEIVED`;
        }
      } else {
        displayStatus = `Status: RECEIVED AND POSTED`;
      }
    }
    // Non-Inventory/Regular PO
    else if (pendingPoModalData.is_received == 1) {
      // PO is Received and Posted
      if (pendingPoModalData.status == 9) {
        displayStatus = `Status: RECEIVED AND POSTED`;
      } // PO is Received and Ready to Post
      else {
        displayStatus = `Status: RECEIVED AND READY TO POST IN BRM`;
      }
    } else {
      if (pendingPoModalData.is_partially_received == null) {
        displayStatus = `Status: NOT YET RECEIVED`;
      } else {
        // If po.is_partially_received == 1 or 0 just mark it as partially received
        // It is not Ready to Post until the whole PO is marked as received
        displayStatus = `Status: PARTIALLY RECEIVED`;
      }
    }
  }

  function CopyToClipboard(containerid) {
    if (document.selection) {
      var range = document.body.createTextRange();
      range.moveToElementText(document.getElementById(containerid));
      range.select().createTextRange();
      document.execCommand("copy");
    } else if (window.getSelection) {
      var range = document.createRange();
      range.selectNode(document.getElementById(containerid));
      window.getSelection().removeAllRanges(range);
      window.getSelection().addRange(range);
      document.execCommand("copy");
    }
  }

  // const handleFileSelect = async (event) => {
  //   const originalFile = event.target.files[0];
  //   console.log("originalFile instanceof Blob", originalFile instanceof Blob); // true
  //   console.log(`originalFile size ${originalFile.size / 1024 / 1024} MB`);

  //   const options = {
  //     maxSizeMB: 1,
  //     maxWidthOrHeight: 1920,
  //     useWebWorker: true,
  //   };

  //   // Compress File (returns Blob instead of File)
  //   let compressedBlob = await imageCompression(originalFile, options);
  //   // Convert Compressed Blob back to File
  //   let compressedFile = new File([compressedBlob], originalFile.name, {
  //     type: originalFile.type,
  //   });
  //   const compressedFileUrl = URL.createObjectURL(compressedFile);
  //   console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`);
  //   console.log("compressedFile", compressedFile);
  //   console.log("compressedFileURL", compressedFileUrl);
  //   setPendingPoModalData((prev) => ({
  //     ...prev,
  //     imageBlobs: [...prev.imageBlobs, compressedFile],
  //     imageUrls: [...prev.imageUrls, compressedFileUrl],
  //   }));
  // };

  const handleFileSelect = async (event) => {
    const originalFiles = event.target.files;
    let compressedFiles = [];
    let compressedFileUrls = [];
    for (const originalFile of originalFiles) {
      const originalFileUrl = URL.createObjectURL(originalFile);
      console.log("originalFile", originalFile);
      console.log("originalFileURL", originalFileUrl);
      console.log("originalFile instanceof Blob", originalFile instanceof Blob); // true
      console.log(`originalFile size ${originalFile.size / 1024 / 1024} MB`);

      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };

      // Compress File (returns Blob instead of File)
      let compressedBlob = await imageCompression(originalFile, options);
      // Convert Compressed Blob back to File
      let compressedFile = new File([compressedBlob], originalFile.name, {
        type: originalFile.type,
      });
      const compressedFileUrl = URL.createObjectURL(compressedFile);

      console.log(
        `compressedFile size ${compressedFile.size / 1024 / 1024} MB`
      );
      console.log("compressedFile", compressedFile);
      console.log("compressedFileURL", compressedFileUrl);
      compressedFiles.push(compressedFile);
      compressedFileUrls.push(compressedFileUrl);
    }

    setPendingPoModalData((prev) => ({
      ...prev,
      imageBlobs: [...prev.imageBlobs, ...compressedFiles],
      imageUrls: [...prev.imageUrls, ...compressedFileUrls],
    }));
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={pendingPoModalData != null}
      onClose={() => {
        if (!isLoading) setPendingPoModalData(null);
      }}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          flex: 1,
          overflow: "scroll",
          padding: 16,
        }}
      >
        {pendingPoModalData && (
          <Box sx={{ width: "100%", position: "relative" }}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{ textAlign: "center", fontWeight: "bold" }}
            >
              {pendingPoModalData.poid}
            </Typography>

            <Button
              variant="contained"
              onClick={() => {
                setShareOpen(!shareOpen);
              }}
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
                margin: 0,
                padding: 0,
                // textTransform: "capitalize",
              }}
            >
              {shareOpen ? "Hide" : "Share"}
            </Button>
          </Box>
        )}
        {shareOpen && (
          <Card
            sx={{
              marginTop: "8px",
              padding: "16px",
              marginBottom: "16px",
            }}
          >
            <div style={{ marginBottom: "16px" }}>
              <div
                id="copy-share-message"
                style={{
                  flex: 1,
                  borderColor: "rgba(0, 0, 0, 0.3)",
                  borderStyle: "solid",
                  borderWidth: "1px",
                  borderRadius: "4px",
                  padding: "8px",
                  marginBottom: "8px",
                }}
              >
                <p>
                  Here's the link to PO{" "}
                  <a
                    href={`https://www.material.brm-app.com/pending-pos/${pendingPoModalData?.id}`}
                  >
                    {pendingPoModalData?.poid}
                  </a>{" "}
                  for {pendingPoModalData?.job_name}. Click for details and to
                  check in the order.
                </p>
                <p>
                  {" "}
                  <a
                    href={`https://www.material.brm-app.com/pending-pos/${pendingPoModalData?.id}`}
                  >
                    {`https://www.material.brm-app.com/pending-pos/${pendingPoModalData?.id}`}
                  </a>
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  size="small"
                  variant="contained"
                  // sx={{
                  //   textTransform: "capitalize",
                  // }}
                  onClick={() => {
                    let successMessage = `Message Copied to Clipboard`;

                    CopyToClipboard("copy-share-message");
                    openSnackMessage("success", successMessage);
                  }}
                >
                  Copy Message
                </Button>
              </div>
            </div>

            <div>
              <div
                id="copy-link"
                style={{
                  flex: 1,
                  borderColor: "rgba(0, 0, 0, 0.3)",
                  borderStyle: "solid",
                  borderWidth: "1px",
                  borderRadius: "4px",
                  padding: "8px",
                  marginBottom: "8px",
                }}
              >
                <p>
                  <a
                    href={`https://www.material.brm-app.com/pending-pos/${pendingPoModalData?.id}`}
                  >
                    {`https://www.material.brm-app.com/pending-pos/${pendingPoModalData?.id}`}
                  </a>
                </p>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  size="small"
                  variant="contained"
                  // sx={{
                  //   textTransform: "capitalize",
                  // }}
                  onClick={() => {
                    let successMessage = `Link Copied to Clipboard`;

                    // navigator.clipboard.writeText(
                    //   `https://www.material.brm-app.com/pending-pos/${pendingPoModalData?.id}`
                    // );
                    CopyToClipboard("copy-link");
                    openSnackMessage("success", successMessage);
                  }}
                >
                  Copy Link
                </Button>
              </div>
            </div>
          </Card>
        )}

        {
          // DETAILS
          pendingPoModalData && (
            <Card sx={{ padding: "8px", fontSize: 12 }}>
              <div>{displayStatus}</div>
              {pendingPoModalData.job_name && (
                <div>For Job: {pendingPoModalData.job_name}</div>
              )}
              {pendingPoModalData.vendor_name && (
                <div>Vendor: {pendingPoModalData.vendor_name}</div>
              )}
              {pendingPoModalData.date_created && (
                <div>
                  Order Date:{" "}
                  {momentPhoenix(pendingPoModalData.date_created).format(
                    "MM/DD/YY"
                  )}
                </div>
              )}
              {pendingPoModalData.delivery_date && (
                <div>
                  Expected Delivery Date:{" "}
                  {momentPhoenix(pendingPoModalData.delivery_date).format(
                    "MM/DD/YY"
                  )}
                </div>
              )}
              {pendingPoModalData.deliver_type_description && (
                <div>
                  Delivery Type: {pendingPoModalData.deliver_type_description}
                </div>
              )}

              {pendingPoModalData.yard_delivery_type && (
                <div>
                  Yard Delivery Type: {pendingPoModalData.yard_delivery_type}
                </div>
              )}
              {pendingPoModalData.jobsite_delivery_type_description && (
                <div>
                  Jobsite Delivery Type:{" "}
                  {pendingPoModalData.jobsite_delivery_type_description}
                </div>
              )}

              <div>
                PO Notes:{" "}
                {pendingPoModalData && pendingPoModalData.notes
                  ? pendingPoModalData.notes
                  : ""}
              </div>

              <div>
                Building ID:{" "}
                {pendingPoModalData && pendingPoModalData.building_id
                  ? pendingPoModalData.building_id
                  : ""}
              </div>

              {pendingPoModalData.receive_notes && (
                <div>Receiver Notes: {pendingPoModalData.receive_notes}</div>
              )}
            </Card>
          )
        }

        {
          // PO ITEMS
          pendingPoModalData && (
            <div style={{ marginTop: 16 }}>
              {isSmallScreen ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <h4 style={{ flex: 1 }}>Materials</h4>
                  <div
                    style={{
                      minWidth: 100,
                      fontSize: 12,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <p
                      style={{
                        margin: 0,
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      Received All
                    </p>
                    <Checkbox
                      disabled={!isPoEditable || pendingPoModalData.is_received}
                      checked={checkedAll}
                      onChange={(e) => {
                        handleReceiveAllCheckChange(e.target.checked);
                      }}
                    />
                  </div>
                </div>
              ) : (
                <div>
                  <h4>Materials</h4>
                  <div
                    style={{
                      borderBottomWidth: 1,
                      borderBottomColor: "#d3d3d3",
                      borderBottomStyle: "solid",
                      borderRadius: 0,
                      marginTop: 1,
                      display: "flex",
                      flexDirection: "row",
                      padding: 8,
                      //cursor: "pointer",
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                        fontSize: 12,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <p
                        style={{
                          margin: 0,
                          fontWeight: "bold",
                          //textAlign: "center",
                        }}
                      >
                        Description
                      </p>
                    </div>
                    <div
                      style={{
                        minWidth: 80,
                        fontSize: 12,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <p
                        style={{
                          margin: 0,
                          fontWeight: "bold",
                          textAlign: "center",
                        }}
                      >
                        UOM
                      </p>
                    </div>
                    <div
                      style={{
                        minWidth: 100,
                        fontSize: 12,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <p
                        style={{
                          margin: 0,
                          fontWeight: "bold",
                          textAlign: "center",
                        }}
                      >
                        Qty
                      </p>
                    </div>
                    <div
                      style={{
                        minWidth: 100,
                        fontSize: 12,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <p
                        style={{
                          margin: 0,
                          fontWeight: "bold",
                          textAlign: "center",
                        }}
                      >
                        Received
                      </p>
                      <Checkbox
                        disabled={
                          !isPoEditable || pendingPoModalData.is_received
                        }
                        checked={checkedAll}
                        onChange={(e) => {
                          handleReceiveAllCheckChange(e.target.checked);
                        }}
                      />
                    </div>
                    {
                      // Spacer if Allowing Delete
                      allowDelete && !pendingPoModalData.is_received && (
                        <div
                          style={{
                            width: "24px",
                          }}
                        />
                      )
                    }
                  </div>
                </div>
              )}
              {pendingPoModalData.items &&
                pendingPoModalData.items.map((item, index) => {
                  if (!item.is_not_material)
                    return (
                      <EditPoItem
                        key={index}
                        disableDelete={
                          !(allowDelete && !pendingPoModalData.is_received)
                        }
                        disabled={
                          !isPoEditable || pendingPoModalData.is_received
                        }
                        poItem={item}
                        enableUpdateQuantity={isUserAdmin}
                        onUpdateOrderedQuantity={(orderedQuantity) => {
                          // Admin Only
                          handleItemOrderedQuantityChange(
                            orderedQuantity,
                            index
                          );
                        }}
                        onUpdateReceivedQuantity={(receivedQuantity) => {
                          handleItemReceivedQuantityChange(
                            receivedQuantity,
                            index
                          );
                        }}
                        onDelete={() => {
                          let poItems = [...pendingPoModalData.items];
                          poItems[index] = {
                            ...poItems[index],
                            is_deleted: true,
                          };
                          setPendingPoModalData((prev) => ({
                            ...prev,
                            items: poItems,
                          }));
                        }}
                      />
                    );
                })}
            </div>
          )
        }
        {
          // NON-MATERIAL ITEMS (ADMIN ONLY)
          pendingPoModalData && role?.toLowerCase() == "admin" && (
            <div>
              <h4>Non-Material Items</h4>
              {!isSmallScreen && (
                <div style={{ marginTop: 16 }}>
                  <div
                    style={{
                      borderBottomWidth: 1,
                      borderBottomColor: "#d3d3d3",
                      borderBottomStyle: "solid",
                      borderRadius: 0,
                      marginTop: 1,
                      display: "flex",
                      flexDirection: "row",
                      padding: 8,
                      //cursor: "pointer",
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                        fontSize: 12,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <p
                        style={{
                          margin: 0,
                          fontWeight: "bold",
                          //textAlign: "center",
                        }}
                      >
                        Description
                      </p>
                    </div>
                    <div
                      style={{
                        minWidth: 80,
                        fontSize: 12,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <p
                        style={{
                          margin: 0,
                          fontWeight: "bold",
                          textAlign: "center",
                        }}
                      >
                        UOM
                      </p>
                    </div>
                    <div
                      style={{
                        minWidth: 100,
                        fontSize: 12,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <p
                        style={{
                          margin: 0,
                          fontWeight: "bold",
                          textAlign: "center",
                        }}
                      >
                        Qty
                      </p>
                    </div>
                    <div
                      style={{
                        minWidth: 100,
                        fontSize: 12,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <p
                        style={{
                          margin: 0,
                          fontWeight: "bold",
                          textAlign: "center",
                        }}
                      >
                        Price
                      </p>
                    </div>
                    {
                      // Spacer if Allowing Delete
                      allowDelete && (
                        <div
                          style={{
                            width: "24px",
                          }}
                        />
                      )
                    }
                  </div>
                </div>
              )}
              {pendingPoModalData?.items &&
                pendingPoModalData.items.map((item, index) => {
                  if (item.is_not_material)
                    return (
                      <EditNonMaterialPoItem
                        key={index}
                        disableDelete={!allowDelete}
                        disabled={!isUserAdmin || !isPoEditable}
                        poItem={item}
                        onUpdatePrice={(price) => {
                          handleItemPriceChange(price, index);
                        }}
                        onDelete={() => {
                          let poItems = [...pendingPoModalData.items];
                          poItems[index] = {
                            ...poItems[index],
                            is_deleted: true,
                          };
                          setPendingPoModalData((prev) => ({
                            ...prev,
                            items: poItems,
                          }));
                        }}
                      />
                    );
                })}
              {isUserAdmin && isPoEditable && (
                <Button
                  style={{ flex: 1, maxWidth: 150 }}
                  disabled={isLoading}
                  variant="outlined"
                  onClick={() => {
                    let taxLine = {
                      poid: pendingPoModalData.id,
                      description: "TAX",
                      quantity: 1,
                      price: 0,
                      unit_measure_desc: "EA",
                      is_not_material: 1,
                    };
                    let poItems = [...pendingPoModalData.items, taxLine];

                    setPendingPoModalData((prev) => ({
                      ...prev,
                      items: poItems,
                    }));
                  }}
                  startIcon={<AddIcon />}
                >
                  Add Tax
                </Button>
              )}
            </div>
          )
        }

        <div>
          <h4>Images</h4>
          <Typography
            id="modal-modal-title"
            variant="p"
            component="p"
            sx={{ margin: 0 }}
          >
            Image of the packing slip is required to mark the PO as received.
            Maximum 30 images
          </Typography>
          <div
            style-={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {/* <Card
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                overflowY: "auto",
                width: "100%",
                margin: "0 auto 0 0",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  height: "100%",
                  maxWidth: "100px", // This is actually ignored but is making the horizontal scroll work
                }}
              > */}
            <Grid
              container
              padding={0}
              spacing={0}
              alignItems="center"
              justifyContent="center"
            >
              {
                //Saved Images
                pendingPoModalData &&
                  pendingPoModalData.images &&
                  pendingPoModalData.images.map((entity_image, index) => (
                    <div
                      key={index}
                      style={{ position: "relative", margin: 0, padding: 0 }}
                    >
                      <img
                        onClick={() => {
                          window.open(
                            entity_image.image_url,
                            "_blank",
                            "noopener,noreferrer"
                          );
                        }}
                        src={entity_image.image_url}
                        style={{
                          cursor: "pointer",
                          minHeight: "100px",
                          maxHeight: "100px",
                          minWidth: "100px",
                          maxWidth: "100px",
                          marginRight: "3px",
                          border: "1px solid black",
                        }}
                      />
                      {isPoEditable && (
                        <Close
                          color="error"
                          style={{
                            position: "absolute",
                            top: 8,
                            right: 8,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            let imagesCopy = pendingPoModalData.images;
                            imagesCopy.splice(index, 1);
                            setPendingPoModalData((prev) => ({
                              ...prev,
                              images: imagesCopy,
                            }));
                          }}
                        />
                      )}
                    </div>
                  ))
              }
              {
                //Unsaved Images
                pendingPoModalData &&
                  pendingPoModalData.imageUrls &&
                  pendingPoModalData.imageUrls.length > 0 &&
                  pendingPoModalData.imageUrls.map((url, index) => (
                    <div
                      key={index}
                      style={{ position: "relative", margin: 0, padding: 0 }}
                    >
                      <img
                        src={url}
                        style={{
                          minHeight: "100px",
                          maxHeight: "100px",
                          minWidth: "100px",
                          maxWidth: "100px",
                          marginRight: "3px",
                          border: "1px solid black",
                        }}
                      />
                      <Close
                        color="error"
                        style={{
                          position: "absolute",
                          top: 8,
                          right: 8,
                        }}
                        onClick={() => {
                          // Remove unsaved image from imageBlobs and imageUrls arrays
                          let imageBlobsCopy = pendingPoModalData.imageBlobs;
                          let imageUrlsCopy = pendingPoModalData.imageUrls;
                          imageBlobsCopy.splice(index, 1);
                          imageUrlsCopy.splice(index, 1);
                          setPendingPoModalData((prev) => ({
                            ...prev,
                            imageBlobs: imageBlobsCopy,
                            imageUrls: imageUrlsCopy,
                          }));
                        }}
                      />
                    </div>
                  ))
              }
            </Grid>
            {/* </div>
            </Card> */}
            {isPoEditable && (
              <div
                style={{
                  display: "flex",
                  height: "100%",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  marginTop: "4px",
                }}
              >
                <label>
                  <input
                    ref={uploadRef}
                    multiple
                    style={{ display: "none" }}
                    type="file"
                    accept="image/*"
                    name="images"
                    // capture="environment"
                    onChange={(event) => {
                      handleFileSelect(event);
                    }}
                  />
                  <Button
                    size="small"
                    variant="contained"
                    onClick={() => {
                      uploadRef.current.click();
                    }}
                  >
                    <FileUpload />
                    Upload
                  </Button>
                </label>
                <label>
                  <input
                    ref={cameraRef}
                    multiple
                    style={{ display: "none" }}
                    type="file"
                    accept="image/*"
                    name="images"
                    capture="environment"
                    onChange={(event) => {
                      handleFileSelect(event);
                      // console.log("FILE: ", event.target.files[0]);
                      // console.log(
                      //   "Image",
                      //   URL.createObjectURL(event.target.files[0])
                      // );
                      // const file = event.target.files[0];
                      // setPendingPoModalData((prev) => ({
                      //   ...prev,
                      //   imageBlobs: [...prev.imageBlobs, file],
                      //   imageUrls: [
                      //     ...prev.imageUrls,
                      //     URL.createObjectURL(event.target.files[0]),
                      //   ],
                      // }));
                    }}
                  />
                  <Button
                    size="small"
                    variant="contained"
                    sx={{ marginLeft: "8px" }}
                    onClick={() => {
                      cameraRef.current.click();
                    }}
                  >
                    <AddAPhoto sx={{ marginRight: "4px" }} /> Capture
                  </Button>
                </label>
              </div>
            )}
          </div>

          <TextareaAutosize
            placeholder="Add Receiver Notes..."
            minRows={3}
            style={{
              width: "100%",
              marginTop: 16,
              background: isDarkMode
                ? "#232323"
                : theme.palette.primary.extraDark,
              color: theme.palette.secondary.contrastText,
              borderColor: theme.palette.primary.main,
            }}
            value={
              pendingPoModalData && pendingPoModalData.receive_notes
                ? pendingPoModalData.receive_notes
                : ""
            }
            onChange={(e) => {
              setPendingPoModalData((prev) => ({
                ...prev,
                receive_notes: e.target.value,
              }));
            }}
          />
        </div>

        <Card style={{ padding: 8 }}>
          <h3>Update Logs</h3>

          {pendingPoModalData?.update_logs?.map((log, index) => (
            <div key={index}>
              <div style={{ fontWeight: "bold" }}>
                {momentPhoenix(new Date(log.created_on)).format(
                  "MM/DD/YYYY hh:mm A z"
                )}{" "}
                By {log.created_by_full_name}
              </div>
              {log.log.split("*").map((line, index) => (
                <p key={index}>{line}</p>
              ))}
            </div>
          ))}
        </Card>
        <DialogActions
          style={{
            display: "flex",
            flexDirection: isSmallScreen ? "column-reverse" : "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            color="secondary"
            style={{
              marginTop: 12,
              color: "#ffffff",
              width: isSmallScreen ? "100%" : 150,
            }}
            disabled={isLoading}
            variant="contained"
            onClick={() => {
              setPendingPoModalData(null);
            }}
          >
            Back
          </Button>
          <div
            style={{
              marginTop: 12,
              flex: isSmallScreen ? 1 : "	0 1 auto",
              display: "flex",
              flexDirection: "row",
              width: isSmallScreen ? "100%" : "inherit",
              alignItems: "center",
              justifyContent: isSmallScreen ? "center" : "flex-start",
            }}
          >
            {isPoEditable && (
              <LoadingButton
                style={{ flex: 1, maxWidth: 150, marginLeft: "8px" }}
                loading={isLoading}
                variant="contained"
                onClick={() => {
                  updatePo();
                }}
              >
                Partially Received
              </LoadingButton>
            )}
            {isPoEditable && (
              <LoadingButton
                style={{ flex: 1, maxWidth: 150, marginLeft: "8px" }}
                disabled={!enableReceive}
                loading={isLoading}
                variant="contained"
                onClick={() => {
                  updateAndReceivePo();
                }}
              >
                Received in Full
              </LoadingButton>
            )}
            {isUserAdmin && isPoEditable && pendingPoModalData.is_received && (
              <LoadingButton
                style={{ flex: 1, maxWidth: 150, marginLeft: "8px" }}
                disabled={!enablePost}
                loading={isLoading}
                variant="contained"
                onClick={() => {
                  updateAndUnlockReceivedPo();
                }}
              >
                Undo Receive
              </LoadingButton>
            )}
            {/* {isUserAdmin && isPoEditable && (
              <LoadingButton
                style={{ flex: 1, maxWidth: 150, marginLeft: "8px" }}
                disabled={!enablePost}
                loading={isLoading}
                variant="contained"
                onClick={() => {
                  updateAndPostPo();
                }}
              >
                Post
              </LoadingButton>
            )} */}
          </div>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default PendingPoModal;
