import React, { useContext, useEffect, useState } from "react";
import { ArrowCircleLeft, ArrowCircleRight } from "@mui/icons-material";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextareaAutosize,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import InventoryTracking from "../../../api/InventoryTracking";
import { SnackAlertContext } from "../../../context/SnackAlertContext";
import PageContent from "../../../component/PageContent";
import { useTheme } from "@emotion/react";
import momentPhoenix from "../../../helpers/momentPhoenix";

const InventoryTransactions = () => {
  const navigate = useNavigate();
  const { authToken, userBranch } = useContext(AuthContext);
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const { openSnackMessage } = useContext(SnackAlertContext);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [date, setDate] = useState(momentPhoenix().toDate());

  const changeDate = (direction) => {
    if (direction === "Prev") {
      setDate(momentPhoenix(date).subtract(1, "day").toDate());
    } else if (direction === "Next") {
      setDate(momentPhoenix(date).add(1, "day").toDate());
    }
  };

  const getTransactions = async (controller) => {
    try {
      setLoading(true);

      let data = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      if (controller) {
        data.signal = controller.signal;
      }

      const response = await InventoryTracking.get(
        `/inventory/transactions?startDate=${date}&endDate=${date}`,
        data
      );
      console.log("Transactions", response.data);
      setTransactions(response.data.transactions);

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    const controller = new AbortController();

    getTransactions(controller);
    return () => {
      controller.abort(); // abort api call on unmount for cleanup
    };
  }, [date]);

  return (
    <PageContent>
      <div
        style={{
          position: "relative",
          flexDirection: "row",
          display: isSmallScreen ? "column" : "row",
          marginTop: 16,
          marginLeft: 16,
          marginRight: 16,
        }}
      >
        <h2
          style={{
            textAlign: "center",
            //background: "#d2d2d2",
            flex: 1,
            margin: 0,
          }}
        >
          Inventory Transactions
        </h2>
      </div>
      <div>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <ArrowCircleLeft
            size="small"
            variant="outlined"
            onClick={() => changeDate("Prev")}
          ></ArrowCircleLeft>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="caption" sx={{ margin: "auto" }}>
              Date Of:
            </Typography>
            <Typography variant="button" sx={{ mr: "5px", ml: "5px" }}>
              {momentPhoenix(date).format("MM/DD/YYYY")}
            </Typography>
          </Box>

          <ArrowCircleRight
            size="small"
            variant="outlined"
            onClick={() => changeDate("Next")}
          ></ArrowCircleRight>
        </Box>
      </div>
      <TableContainer component={Box}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Branch</TableCell>
              <TableCell>Item</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Transaction ID</TableCell>
              <TableCell>Job</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactions &&
              transactions.map((transaction, index) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>{transaction.branch}</TableCell>
                  <TableCell>{transaction.description}</TableCell>
                  <TableCell
                    style={{
                      color: transaction.qty < 0 ? "red" : "green",
                    }}
                  >
                    {transaction.qty < 0 ? "" : "+"}
                    {transaction.qty}
                  </TableCell>
                  <TableCell>{transaction.reference}</TableCell>
                  <TableCell>{transaction.job_name}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </PageContent>
  );
};

export default InventoryTransactions;
