import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Grid,
  TextareaAutosize,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import InventoryTracking from "../../../api/InventoryTracking";
import { AuthContext } from "../../../context/AuthContext";
import ImageModalButton from "../../../component/ImageModalButton";
import { GrabsContext } from "../../../context/GrabsContext";
import { Check, PanTool, ShoppingCart } from "@mui/icons-material";
import PrintableQRCode from "../../../component/PrintableQRCode";
import { SnackAlertContext } from "../../../context/SnackAlertContext";
import PageContent from "../../../component/PageContent";
import clickOnceWrapper from "../../../helpers/clickOnceWrapper";
import toFixedIfNecessary from "../../../helpers/toFixedIfNecessary";
import { useTheme } from "@emotion/react";
import { DarkLightModeContext } from "../../../context/DarkLightModeContext";

const BoneyardItemDetails = () => {
  const { isDarkMode } = useContext(DarkLightModeContext);
  const { id } = useParams();
  const { authToken, role } = useContext(AuthContext);
  const { openSnackMessage } = useContext(SnackAlertContext);
  const [details, setDetails] = useState(null);
  const [notes, setNotes] = useState("");
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [currentQuantity, setCurrentQuantity] = useState(0);

  let navigate = useNavigate();
  const { grabFromBoneyard } = useContext(GrabsContext);

  const getBoneyardItemDetails = async () => {
    try {
      const response = await InventoryTracking.get(`/boneyard/${id}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      console.log(response.data.material);
      setDetails(response.data.material);
      setNotes(response.data.material.notes);
      setCurrentQuantity(response.data.material.qty);
    } catch (error) {
      console.log(error);
    }
  };

  const updateImage = async (imageBlob) => {
    try {
      let formData = new FormData();
      formData.append("id", id);
      if (imageBlob) formData.append("image", imageBlob);
      else formData.append("image", null);

      console.log(formData);
      const response = await InventoryTracking.post(`/boneyard`, formData, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "content-type": "multipart/form-data",
        },
      });
      console.log("Image Update response", response.data);
      openSnackMessage("success", "Item Image Updated");
      setDetails(response.data.material);
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.message);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", "Internal Server Error");
      }
    }
  };

  const verifyCount = async () => {
    try {
      let data = { count: currentQuantity };
      const response = await InventoryTracking.put(`/boneyard/${id}`, data, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      console.log(response.data);
      openSnackMessage("success", "Item Quantity Updated");
      setDetails(response.data.material);
      setCurrentQuantity(response.data.material.qty);
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.message);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", "Internal Server Error");
      }
    }
  };

  const updateNotes = async () => {
    try {
      let data = {
        id,
        notes,
      };
      const response = await InventoryTracking.post(`/boneyard`, data, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      openSnackMessage("success", "Item Notes Updated");
      setDetails(response.data.material);
      setNotes(response.data.material.notes);
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.message);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", "Internal Server Error");
      }
    }
  };

  useEffect(() => {
    getBoneyardItemDetails();
  }, []);

  if (true)
    return (
      <PageContent>
        {details ? (
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={4}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div style={{ marginTop: 16 }}>
                {!isSmallScreen && (
                  <div
                    style={{
                      background: "white",
                      padding: "16px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <PrintableQRCode
                      printSize={100}
                      displaySize={150}
                      url={`${window.location.host}/boneyard/${id}`}
                      subtext={details.item}
                      topText="BONEYARD"
                    />
                  </div>
                )}
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    style={{ width: 150, height: 50 }}
                    // disabled={imageModal.imageBlob === null}
                    variant="contained"
                    onClick={clickOnceWrapper(() => {
                      grabFromBoneyard(details);
                      navigate("/daily-stockouts/new");
                    })}
                    startIcon={<ShoppingCart />}
                  >
                    Stock Out
                  </Button>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <div style={{ padding: 16 }}>
                    <h3 style={{ textAlign: "center" }}>{details.item}</h3>
                    <div style={{ textAlign: "center" }}>
                      Quantity: {details.qty}
                    </div>
                    <div style={{ textAlign: "center" }}>
                      UOM: {details.uom_description}
                    </div>
                    <div style={{ textAlign: "center" }}>
                      Branch: {details.branch}
                    </div>

                    <div style={{ textAlign: "center" }}>Notes:</div>
                    <TextareaAutosize
                      minRows={3}
                      aria-label="empty textarea"
                      placeholder="Empty"
                      style={{
                        width: "100%",
                        background: isDarkMode
                          ? "#232323"
                          : theme.palette.primary.extraDark,
                        color: theme.palette.secondary.contrastText,
                        borderColor: theme.palette.primary.main,
                      }}
                      value={notes}
                      onChange={(e) => {
                        setNotes(e.target.value);
                      }}
                    />
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Button
                        disabled={details.notes === notes}
                        variant="outlined"
                        onClick={async () => {
                          updateNotes();
                        }}
                      >
                        Update Notes
                      </Button>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div style={{ padding: 16 }}>
                    <ImageModalButton
                      currentImage={details.image_url}
                      onSubmit={(imageBlob) => {
                        console.log("Image Submit");
                        updateImage(imageBlob);
                      }}
                    />

                    {role && role.toLowerCase() === "admin" ? (
                      <Grid
                        container
                        style={{
                          flex: 1,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginTop: 16,
                          marginBottom: 16,
                        }}
                      >
                        <Grid item md={7}>
                          <div
                            style={{
                              flex: 1,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              marginTop: 16,
                              marginBottom: 16,
                            }}
                          >
                            <TextField
                              style={{ marginRight: 16 }}
                              id="time"
                              type="number"
                              label={`Current Quantity (${details.uom_description})`}
                              value={`${currentQuantity}`}
                              onChange={(e) => {
                                let val;
                                if (
                                  e.target.value == "" ||
                                  e.target.value === undefined ||
                                  e.target.value === null ||
                                  e.target.value === "0" ||
                                  e.target.value === "00"
                                ) {
                                  val = 0;
                                } else {
                                  val = e.target.value.replace(/^0+/, "");
                                  val = toFixedIfNecessary(val, 2);
                                }
                                // Do Not Allow Negative
                                // if (val < 0) {
                                //   val = 0;
                                // }
                                setCurrentQuantity(val);
                              }}
                            />
                          </div>
                        </Grid>
                        <Grid item md={5}>
                          <Button
                            color="secondary"
                            style={{ color: "#fff" }}
                            disabled={currentQuantity === details.qty}
                            variant="contained"
                            onClick={async () => {
                              verifyCount();
                            }}
                            startIcon={<Check />}
                          >
                            Adjust
                          </Button>
                        </Grid>
                      </Grid>
                    ) : null}
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <div style={{ height: "100%" }}>
            <div>Loading...</div>
          </div>
        )}
      </PageContent>
    );
};

export default BoneyardItemDetails;
